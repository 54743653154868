export function allowAlphabetAndSpacesOnly(e) {
    var keyCode = e.charCode;
  
    var value = Number(e.target.value + e.key) || 0;
  
    if (
      !(keyCode >= 65 && keyCode <= 123) &&
      keyCode !== 32 &&
      keyCode !== 0 &&
      keyCode !== 40 &&
      keyCode !== 41
    ) {
      e.preventDefault();
    } else {
      return value;
    }
  
    return false;
  }
  
  export default function allowNumbersOnly(e) {
    var keyCode = e.keyCode === 0 ? e.charCode : e.keyCode;
  
    var value = Number(e.target.value + e.key) || 0;
  
    if (keyCode >= 48 && keyCode <= 57) {
      return isValidNumber(value);
    } else {
      e.preventDefault();
    }
  
    return false;
  }
  
  const isValidNumber = (number) => {
    return 1 <= number && number <= 10;
  };
  export const AADHAR_VALIDATION = /^[1-9]{1}[0-9]{11}$/
  // export  const PAN_VALIDATION = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
  export const PAN_VALIDATION = /^[A-Z]{3}P[A-Z]\d{4}[A-Z]$/;

  export  const IFSC_VALIDATION = /^[A-Z]{4}0[A-Z 0-9]{6}$/;

  export const isValidPanNumber = (panNumber) => {
    const PAN_VALIDATION = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
    return PAN_VALIDATION.test(panNumber);
};
