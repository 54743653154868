import { ErrorMessage, Field, Formik, FormikProvider, useFormik } from "formik";
import * as jnb from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { config } from "../../../../CommonUtils/CommonApis";
import { CommonAxiosGet } from "../../../../CommonUtils/CommonAxios";
import Sweetalert, { SweetalertOKFunction } from "../../../../CommonUtils/SweetAlerts";
import * as Yup from "yup";
import allowNumbersOnly, { AADHAR_VALIDATION, IFSC_VALIDATION, PAN_VALIDATION, allowAlphabetAndSpacesOnly} from "../../../../CommonUtils/CommonValidations";
import Swal from "sweetalert2";
import CommonPost from "../../../../CommonUtils/CommonPost";

export default function EmployeeDashboard() {
    const EmployeeDashboardformIk = useFormik({
        enableReinitialize: true,
        initialValues: {category: "",},
        onSubmit: (values) => { console.log(values)},
    });
    const [intialDataAtEmpDashboard, setInitialDataAtEmpDashboard] = useState({
        empId: "",
        empName: "",
        dob: "",
        doj: "",
        gender: "",
        email: "",
        mobileNo: "",
        zoneId: "",
        designationId: "",
        ioEmpId: "",
        roEmpId: "",
        renewaldate: "",
        category: "",
        department: "",
        level: "",
        projectwise: "",
        toatalexperience: "",
        band: "",
        qualification: "",
        aadhar:"",
        married:"",
        pan_no:"",
        bank_account_number:"",
        bankifsccode:"",
        uan_no:"",
        pf_no:"",
        gpf_cps_no:"",
        apgli_no:"",
        gis_category:""
    })
    const navigateAtEmpDashboard = useNavigate();
    const [showCategoryWiseEmpData, setCategoryWiseEmpData] = useState([]);
    useEffect(() => {
            CommonAxiosGet(config.url.COMMON_URL + "pms/category-wise/employees/new").then((response) => {
            if (response.data.scode === "01") {
                setCategoryWiseEmpData(response.data.data);
            } else if (response.data.scode === "02") {
                Sweetalert(response.data.sdesc, "warning");
            }
        });
        GetAllMastersAtEmpDashboard();
    }, [])

    const [showEmployeeDashboardData, setEmployeeDashboardData] = useState([]);
    const [showEmployeeDataBrowser, setEmployeeDataBrowser] = useState(false);
    const [showCategoryName, setCategoryName] = useState("");
    function GetEmployeeData(categoryAtdashboard, categorynameAtdashboard,typeAtdashboard) {
        CommonAxiosGet(config.url.COMMON_URL + "pms/category-wise-new/" + categoryAtdashboard +"/"+typeAtdashboard+ "/employees").then((response) => {
            console.log("testing",response.data)
            if (response.data.status === true) {
                setEmployeeDataBrowser(true);
                setEmployeeDashboardData(response.data.data);
                setCategoryName(categorynameAtdashboard)
            } else if (response.data.status === false) {
                setEmployeeDataBrowser(false);
                Sweetalert(response.data.sdesc, "warning");
            }
        });
    }
    const [searchQueryAtDashboard, setSearchQueryAtDashboard] = useState('');
    const [makerModalAtDashboard, setMakerModalAtDashboard] = useState(false);
    
    const handleSearchChange = (e) => {
        setSearchQueryAtDashboard(e.target.value);
    };
    const filterDataAtEmpDashboard = (dataAttemp, searchQueryAtEmp) =>
        dataAttemp.filter((row_atemp) =>
            Object.values(row_atemp).some((value_atemp) =>
                String(value_atemp).toLowerCase().includes(searchQueryAtEmp.toLowerCase())
            )
        );
    const filteredData1 = filterDataAtEmpDashboard(showEmployeeDashboardData, searchQueryAtDashboard);
    function closeModelAtEmpDashboard() {
        setMakerModalAtDashboard(false)
    }
    const [showCategoryValue,setCategoryValue] = useState()
    const getActionAtEmpDashboard = (rowAt_emp) => {
        setMakerModalAtDashboard(true);
        GetemployeesdataAtDashboard();
        GetdesignationsdataAtDahboard(rowAt_emp.zone_id);
        const newDataDsahbord = {
            ...intialDataAtEmpDashboard,
            empId: rowAt_emp.emp_id,
            empName: rowAt_emp.emp_name,
            dob: rowAt_emp.dob,
            doj: rowAt_emp.doj,
            mobileNo: rowAt_emp.mobile_no,
            email: rowAt_emp.email,
            designationId: rowAt_emp.designation_id,
            zoneId: rowAt_emp.zone_id,
            roEmpId: rowAt_emp.ro_emp_id,
            level: rowAt_emp.level,
            projectwise: rowAt_emp.projectwise,
            renewaldate: rowAt_emp.renewaldate,
            category: rowAt_emp.category,
            department: rowAt_emp.department,
            qualification: rowAt_emp.qualification,
            band: rowAt_emp.band,
            toatalexperience: rowAt_emp.total_exp_apcfss,
            gender: rowAt_emp.gender,
            aadhar: rowAt_emp.aadhar,
            married: rowAt_emp.married + "",
            pan_no: rowAt_emp.pan_no,
            bank_account_number: rowAt_emp.bank_account_number,
            bankifsccode: rowAt_emp.bankifsccode,
        };
        if (rowAt_emp.category === 1) {
            newDataDsahbord.uan_no = rowAt_emp.uan_no;
            newDataDsahbord.pf_no = rowAt_emp.pf_no;
        }
        if (rowAt_emp.category === 5) {
            newDataDsahbord.gpf_cps_no= rowAt_emp.gpf_cps_no;
            newDataDsahbord.apgli_no= rowAt_emp.apgli_no;
            newDataDsahbord.gis_category= rowAt_emp.gis_category
        }
        setInitialDataAtEmpDashboard(newDataDsahbord);
    }
    const [showZoneMasterAtEmpDeshboard, setZoneMasterAtEmpDeshboard] = useState([]);
    const [showDesignationsDataAtEmpDeshboard, setDesignationsDataAtEmpDeshboard] = useState([]);
    const [showCategoryMasterAtEmpDeshboard, setCategoryMasterAtEmpDeshboard] = useState([]);

    const [showDepartmentMasterApEmpDashboard, setDepartmentMasterApEmpDashboard] = useState([]);
    const [showLevelsMasterAtEmpDashboard, setLevelsMasterAtEmpDashboard] = useState([]);
    const [showProjectWiseMasterAtEmpDashboard, setProjectWiseMasterAtEmpDashboard] = useState([]);
    const [showBandMasterAtEmpDashboard, setBandMasterAtPmsDashboard] = useState([]);
        //start..
    const [showQualificationMaster, setQualificationMaster] = useState([]);
    function GetAllMastersAtEmpDashboard() {
        CommonAxiosGet(config.url.COMMON_URL + "zones").then((response) => {
            if (response.data.scode === "01") {
                setZoneMasterAtEmpDeshboard(response.data.data);
            } else if (response.data.scode === "02") {
                Sweetalert(response.data.sdesc, "warning");
            }
        });
        CommonAxiosGet(config.url.COMMON_URL + "masters/categories").then((response) => {
            if (response.data.scode === "01") {
                setCategoryMasterAtEmpDeshboard(response.data.data);
            } else if (response.data.scode === "02") {
                Sweetalert(response.data.sdesc, "warning");
            }
        });
        CommonAxiosGet(config.url.COMMON_URL + "masters/departments").then((response) => {
            if (response.data.scode === "01") {
                setDepartmentMasterApEmpDashboard(response.data.data);
            } else if (response.data.scode === "02") {
                Sweetalert(response.data.sdesc, "warning");
            }
        });
        CommonAxiosGet(config.url.COMMON_URL + "masters/levels").then((response) => {
            if (response.data.scode === "01") {
                setLevelsMasterAtEmpDashboard(response.data.data);
            } else if (response.data.scode === "02") {
                Sweetalert(response.data.sdesc, "warning");
            }
        });
        CommonAxiosGet(config.url.COMMON_URL + "masters/project-wise").then((response) => {
            if (response.data.scode === "01") {
                setProjectWiseMasterAtEmpDashboard(response.data.data);
            } else if (response.data.scode === "02") {
                Sweetalert(response.data.sdesc, "warning");
            }
        });
        CommonAxiosGet(config.url.COMMON_URL + "masters/bands").then((response) => {
            if (response.data.scode === "01") {
                setBandMasterAtPmsDashboard(response.data.data);
            } else if (response.data.scode === "02") {
                Sweetalert(response.data.sdesc, "warning");
            }
        });
        CommonAxiosGet(config.url.COMMON_URL + "masters/qualifications").then((response) => {
            if (response.data.scode === "01") {
                setQualificationMaster(response.data.data);
            } else if (response.data.scode === "02") {
                Sweetalert(response.data.sdesc, "warning");
            }
        });
    }
    function GetdesignationsdataAtDahboard(zoneid) {
        if (zoneid != null && zoneid != "" && zoneid != undefined) {
            CommonAxiosGet(config.url.COMMON_URL + "designationkps/" + zoneid).then((res) => {
                if (res.data.scode === "01") {
                    setDesignationsDataAtEmpDeshboard(res.data.data)
                }
                else if (res.data.scode === "02") {
                    Sweetalert(res.data.sdesc, 'warning')
                    setDesignationsDataAtEmpDeshboard([])
                }
            })
        }
    }
    const [showemployeelist, setemployeeList] = useState([]);
    function GetemployeesdataAtDashboard() {
        CommonAxiosGet(config.url.COMMON_URL + "employeedata").then((response) => {
            setemployeeList(response.data.emp_data);
        });
    }
    const [showTotalExperienceInMonths, setTotalExperienceInMonths] = useState(0);
    const [showTotalExperienceInYears, setTotalExperienceInYears] = useState(0);
    function calculateTotalExperience(doj) {
        const currentFormattedDate = new Date().toISOString().split('T')[0].split('-');
        const dojFormattedDate = new Date(doj).toISOString().split('T')[0].split('-');
        setTotalExperienceInYears((parseInt(currentFormattedDate[0]) - parseInt(dojFormattedDate[0])))
        setTotalExperienceInMonths((parseInt(currentFormattedDate[1]) - parseInt(dojFormattedDate[1])))
    }
    const [banskDetails,setBankDetails]=useState([])
 
    function fetchBankDetails(ifscCode) {
        if (ifscCode == undefined || ifscCode == "") {
          setBankDetails([]);
        } else {
          let banksUrl = config.url.BANK_URL + "/auth/getRbibanksDetails?ifscCode=" + ifscCode;
          CommonAxiosGet(banksUrl).then((response) => {
            if (response !== undefined) {
              setBankDetails(response.data[0]);
            } else {
              Swal.fire('', 'Something went Wrong', 'error');
              setBankDetails('');
            }
          });
        }
      }
const submitAlert = (values) => {
    Swal.fire({
        title: 'Are you sure you want to Update?',
        text: 'Please check it once before Updating',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Update',
        cancelButtonText: 'Cancel',
        reverseButtons: true
    }).then((result) => {
        if (result.value) {
           
            CommonPost.empEntryPostNew(values)
                .then((res) => {
                    if (res.data.scode === "01") {
                        SweetalertOKFunction('Successfully Submitted ', 'success').then(function (isConfirm) {
                            if (isConfirm.value) {
                                window.location.reload();
                            }
                        });
                    } else {
                        Swal.fire('Canceled', 'Something went wrong,Please check', 'error');
                    }
                })
                .catch(() => {
                    // Handle submission error
                });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
            Swal.fire('Canceled', 'Do corrections and then retry :)', 'error');
        }
    });
};

    function NavigateNewEmployee(){
        navigateAtEmpDashboard("/employeeentryformnew")
    }
function clearFunction(value){
    if(value === "5"){
        setInitialDataAtEmpDashboard({ ...intialDataAtEmpDashboard, gpf_cps_no: "" });
        setInitialDataAtEmpDashboard({ ...intialDataAtEmpDashboard, apgli_no: "" });
        setInitialDataAtEmpDashboard({ ...intialDataAtEmpDashboard, gis_category: "" });

    }
    else if(value ==="1"){
        setInitialDataAtEmpDashboard({ ...intialDataAtEmpDashboard, uan_no: "" });
        setInitialDataAtEmpDashboard({ ...intialDataAtEmpDashboard, pf_no: "" });
   
    }
    
}

    return (<>
        <div className="card">
            <div className="card-body">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to="/homepage">Home</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">Employee Dashboard</li>
                </ol>
            </div>
        </div>
        <FormikProvider value={EmployeeDashboardformIk}>
            <jnb.Form onSubmit={EmployeeDashboardformIk.handleSubmit} onChange={EmployeeDashboardformIk.handleChange}>
                <br />
                <div className="border p-3 pb-0 mb-3">
                <jnb.Row className="px-3 pt-0">
                    <jnb.Col xs={12} sm={12} md={12} lg={12} xl={10} xxl={10}></jnb.Col>
                    <jnb.Col xs={12} sm={12} md={12} lg={12} xl={2} xxl={2}>
                        <button type="button" className="btn rounded-0 btn-success btn-sm mb-2 float-end" onClick={()=>{NavigateNewEmployee()}} >Add New Employee</button>
                    </jnb.Col>
                </jnb.Row>
                    <div className="table-responsive">

                        <table className="table table-condensed table-bordered table-striped table-responsive">
                            <thead>
                                <tr>
                                    <th rowSpan={2}>Sl.No</th>
                                    <th colSpan={2}>Regular</th>
                                    <th colSpan={2}>Contract</th>
                                    <th colSpan={2}>Intern</th>
                                    <th colSpan={2}>Government</th>
                                </tr>
                                <tr>
                                    <th>Active</th>
                                    <th>Inactive</th>
                                    <th>Active</th>
                                    <th>Inactive</th>
                                    <th>Active</th>
                                    <th>Inactive</th>
                                    <th>Active</th>
                                    <th>Inactive</th>
                                </tr>
                            </thead>
                            <tbody style={{ textAlign: "center" }}>
                            <tr >
                                <td>1</td>
                                {showCategoryWiseEmpData != undefined && showCategoryWiseEmpData?.map((iodata, i) => {
                                    return (<React.Fragment key={i}>
                                            <td>
                                                <a href={() => false} className="atag" onClick={(e) => { GetEmployeeData(iodata.category_id, iodata.category_name,"active") }}>{iodata.active}</a>
                                                </td>
                                            <td>
                                                <a 
  href={() => false} 
  className="atag" 
  onClick={(e) => {
    e.preventDefault();

    if (iodata.inactive !== 0) {
      GetEmployeeData(iodata.category_id, iodata.category_name, "inactive");
    }
  }}
>
  {iodata.inactive}
</a>

                                                </td>
                                        
                                    </React.Fragment>)
                                })}
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                {showEmployeeDataBrowser ? (<>
                    <div className="head-text mt-4"> <h5> Employee Details ( <span style={{ color: "darkorange" }}> Category: &nbsp;{showCategoryName}</span>) </h5></div>
                    <br />
                    <input type="text" placeholder="Search..." value={searchQueryAtDashboard} onChange={handleSearchChange} 
                    name={`search_${Date.now()}`} 
                    id="search" />
                    <br />
                    <br /> <div className="border p-3 pb-0 mb-3">
                        <br /><div className="table-responsive">
                            <table className="table table-condensed table-bordered table-striped table-responsive">
                                <thead>
                                    <tr>
                                        <th>Sl.No</th>
                                        <th width="200px">ID (Name)</th>
                                        <th width="89px">DOB</th>
                                        <th width="89px">DOJ</th>
                                        <th>Gender</th>
                                        <th>Age</th>
                                        <th>Zone</th>
                                        <th >Designation</th>
                                        <th width="60px">Band</th>
                                        <th width="145px">Level</th>
                                        <th width="200px">RO Name(ID)</th>
                                        {filteredData1.some(iodata => iodata.releaved === false) && <th width="70px">Edit</th>}
                                    </tr>
                                </thead>
                                <tbody style={{ textAlign: "left" }}>
                                    {filteredData1 != undefined && filteredData1.map((iodata, i) => {
                                        return (<React.Fragment key={i}>
                                            <tr >
                                                <td>{i + 1}</td>
                                                <td>{iodata.emp_id} ({iodata.emp_name})</td>
                                                <td>{iodata.dob}</td>
                                                <td>{iodata.doj}</td>
                                                <td>{iodata.gender}</td>
                                                <td>{iodata.age}</td>
                                                <td>{iodata.zone_name}</td>
                                                <td>{iodata.designation_name}</td>
                                                <td>{iodata.band}</td>
                                                <td>{iodata.level_name}</td>
                                                <td>{iodata.ro_name_id}</td>
                                                {iodata.releaved === false ? (<> <td><button className="btn btn-success btn-sm " type="button"
                                                    onClick={(e) => { getActionAtEmpDashboard(iodata); setCategoryValue(iodata.category) }}
                                                >Edit</button>  </td></>):(<></>)}
                                            </tr>
                                        </React.Fragment>)
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </>) : (<></>)}
                <jnb.Modal  size="xl" aria-labelledby="contained-modal-title-vcenter" centered show={makerModalAtDashboard} fade="false">
                    
                    <Formik initialValues={intialDataAtEmpDashboard} enableReinitialize={true}
                    
                        validationSchema={Yup.object().shape({
                            empId: Yup.string().required("Please Enter Employee ID").nullable(),
                            empName: Yup.string().required("Please Enter Employee Name").nullable(),
                            dob: Yup.string().required("Please select Employee Date of Birth").nullable(),
                            doj: Yup.string().required("Please select Employee Date of Joining").nullable(),
                            gender: Yup.string().required("Please select gender").nullable(),
                            email: Yup.string().required("Please Enter Email").nullable(),
                            mobileNo: Yup.string().required("Please Enter Mobile No").nullable(),
                            zoneId: Yup.string().required("Please select ZoneID").nullable(),
                            designationId: Yup.string().required("Please select Designation Id").nullable(),
                            roEmpId: Yup.string().required("Please select RO Employee Id").nullable(),
                            category: Yup.string().required("Please select Category").nullable(),
                            department: Yup.string().required("Please select Department").nullable(),
                            level: Yup.string().required("Please select Level").nullable(),
                            projectwise: Yup.string().required("Please select Project Wise").nullable(),
                            band: Yup.string().required("Please select Band").nullable(),
                            qualification:Yup.string().required("Please select Qualification").nullable(),
                            aadhar:Yup.string().min(12).matches(AADHAR_VALIDATION, "Invalid Aadhar Number").nullable(),
                            married:Yup.string().required("Required").nullable(),
                            pan_no:Yup.string().max(10).matches(PAN_VALIDATION, "Invalid PAN no").nullable(),
                            bank_account_number:Yup.string().required("Required").nullable(),
                            bankifsccode:Yup.string().required("Required").matches(IFSC_VALIDATION, "Invalid PAN no").nullable(),
                        })}
                        onSubmit={(values, { resetForm }) => {
                            submitAlert(values)
                        }}>
                        {({ handleSubmit, handleChange }) => (
                            <form noValidate onSubmit={handleSubmit} onChange={handleChange}>
                                <jnb.Modal.Header>
                                    <jnb.Modal.Title id="contained-modal-title-vcenter">
                                        Edit
                                    </jnb.Modal.Title>
                                </jnb.Modal.Header>
                                <jnb.Modal.Body>
                                    <jnb.Card.Body >
                                        <jnb.Row className="px-3 pt-4">
                                            <jnb.Col xs={12} sm={12} md={12} lg={12} xl={4} xxl={4}>
                                                <jnb.InputGroup className="mb-4p5">
                                                    <span className="label-text-style"><b>Employee ID:<font style={{ color: "red" }}>*</font></b></span>
                                                    <Field type="text" name="empId" className="form-control" onKeyPress={(e) => { allowNumbersOnly(e); }} maxLength="8"
                                                        disabled={true} />
                                                    <ErrorMessage name="empId" component="div" className="text-error" />
                                                </jnb.InputGroup>
                                            </jnb.Col>
                                            <jnb.Col xs={12} sm={12} md={12} lg={12} xl={4} xxl={4}>
                                                <jnb.InputGroup className="mb-4p5">
                                                    <span className="label-text-style"><b>Employee Name:<font style={{ color: "red" }}>*</font> </b></span>
                                                    <Field type="text" name="empName" className="form-control" onKeyPress={(e) => { allowAlphabetAndSpacesOnly(e); }} />
                                                    <ErrorMessage name="empName" component="div" className="text-error" />
                                                </jnb.InputGroup>
                                            </jnb.Col>
                                            <jnb.Col xs={12} sm={12} md={12} lg={12} xl={4} xxl={4}>
                                            <jnb.InputGroup className="mb-4p5">
                                                <span className="label-text-style" maxLength="12"><b>Aadhar: </b></span>
                                                <Field type="text" name="aadhar" className="form-control" onKeyPress={(e) => { allowNumbersOnly(e); }} maxLength="12"/>
                                                <ErrorMessage name="aadhar" component="div" className="text-error" />
                                            </jnb.InputGroup>
                                        </jnb.Col>
                                            <jnb.Col xs={12} sm={12} md={12} lg={12} xl={4} xxl={4}>
                                                <jnb.InputGroup className="mb-4p5">
                                                    <span className="label-text-style"><b>DOB:<font style={{ color: "red" }}>*</font> </b></span>
                                                    <Field type="date" name="dob" className="form-control" />
                                                    <ErrorMessage name="dob" component="div" className="text-error" />
                                                </jnb.InputGroup>
                                            </jnb.Col>
                                            <jnb.Col xs={12} sm={12} md={12} lg={12} xl={4} xxl={4}>
                                                <jnb.InputGroup className="mb-4p5">
                                                    <span className="label-text-style"><b>DOJ:<font style={{ color: "red" }}>*</font> </b></span>
                                                    <Field type="date" name="doj" className="form-control"
                                                        onChange={(e) => { calculateTotalExperience(e.target.value) }} />
                                                    <ErrorMessage name="doj" component="div" className="text-error" />
                                                </jnb.InputGroup>
                                            </jnb.Col>
                                            <jnb.Col xs={12} sm={12} md={12} lg={12} xl={4} xxl={4}>
                                                <jnb.InputGroup className="mb-4p5">
                                                    <span className="label-text-style"><b>Gender:<font style={{ color: "red" }}>*</font> &nbsp;</b></span>
                                                    <Field type="radio" name="gender" value="M" />
                                                    &nbsp;Male  &nbsp;
                                                    <Field type="radio" name="gender" value="F" />
                                                    &nbsp;Female
                                                    <ErrorMessage name="gender" component="div" className="text-error" />
                                                </jnb.InputGroup>
                                            </jnb.Col>
                                            <jnb.Col xs={12} sm={12} md={12} lg={12} xl={4} xxl={4}>
                        <jnb.InputGroup className="mb-4p5">
                            <span className="label-text-style"><b>Married:<font style={{ color: "red" }}>*</font> &nbsp;</b></span>
                            <Field type="radio" name="married" value="true" />
                            &nbsp;Yes  &nbsp;
                            <Field type="radio" name="married" value="false" />
                            &nbsp;No
                            <ErrorMessage name="married" component="div" className="text-error" />
                        </jnb.InputGroup>
                    </jnb.Col>
                    <jnb.Col xs={12} sm={12} md={12} lg={12} xl={4} xxl={4}>
                                                <jnb.InputGroup className="mb-4p5">
                                                    <span className="label-text-style"><b>Email:<font style={{ color: "red" }}>*</font> </b></span>
                                                    <Field type="email" name="email" className="form-control" />
                                                    <ErrorMessage name="email" component="div" className="text-error" />
                                                </jnb.InputGroup>
                                            </jnb.Col>
                                            <jnb.Col xs={12} sm={12} md={12} lg={12} xl={4} xxl={4}>
                                                <jnb.InputGroup className="mb-4p5">
                                                    <span className="label-text-style"><b>Mobile No:<font style={{ color: "red" }}>*</font> </b></span>
                                                    <Field type="text" name="mobileNo" className="form-control" maxLength="10" onKeyPress={(e) => { allowNumbersOnly(e); }} />
                                                    <ErrorMessage name="mobileNo" component="div" className="text-error" />
                                                </jnb.InputGroup>
                                            </jnb.Col>
                                            <jnb.Col xs={12} sm={12} md={12} lg={12} xl={4} xxl={4}>
                                            <jnb.InputGroup className="mb-4p5">
                                                <span className="label-text-style"><b>Pan No:<font style={{ color: "red" }}>*</font> </b></span>
                                                <Field type="text" name="pan_no" className="form-control" maxLength="10" 
                                                 onChange={(e) => {
                                                    e.target.value = e.target.value.toUpperCase();
                                                    
                                                }} />
                                                <ErrorMessage name="pan_no" component="div" className="text-error" /> 
                                            </jnb.InputGroup>
                                            </jnb.Col>
                                            <jnb.Col xs={12} sm={12} md={12} lg={12} xl={4} xxl={4}>
                                                <jnb.InputGroup className="mb-4p5">
                                                    <span className="label-text-style"><b>Bank A/c Number:<font style={{ color: "red" }}>*</font> </b></span>
                                                    <Field type="text" name="bank_account_number" className="form-control"/>
                                                    <ErrorMessage name="bank_account_number" component="div" className="text-error" />
                                                </jnb.InputGroup>
                                            </jnb.Col>
                    <jnb.Col xs={12} sm={12} md={12} lg={12} xl={4} xxl={4}>
                  <jnb.InputGroup className="mb-4p5">
                    <span className="label-text-style">
                      <b>IFSC Code:<font style={{ color: "red" }}>*</font></b></span>
                    <Field type="text" className="form-control" name="bankifsccode" maxLength="11"
                      onChange={(e) => { if (e?.target?.value?.length === 11) { fetchBankDetails(e?.target?.value); } }}
                      onKeyUp={(e) => { EmployeeDashboardformIk.setFieldValue("bankifsccode", e?.target?.value?.toUpperCase()); }}/>
                    <ErrorMessage name="bankifsccode" component="div" className="text-error" />
                  </jnb.InputGroup>
                </jnb.Col>
                <jnb.Col xs={12} sm={12} md={12} lg={12} xl={4} xxl={4}>
                  <jnb.InputGroup className="mb-4p5">
                    <span className="label-text-style">
                      <b>Bank Name & Branch</b>
                    </span>
                    {banskDetails?.bankName !== "" && banskDetails?.bankName !== undefined && banskDetails?.bankName !== null ? (
                      <> {banskDetails?.bankName + "-" + banskDetails?.branchName}
                      </>) : (<></>)}
                  </jnb.InputGroup>
                </jnb.Col>
                <jnb.Col xs={12} sm={12} md={12} lg={12} xl={4} xxl={4}>
                    <jnb.InputGroup className="mb-4p5">
                        <span className="label-text-style"><b>Category of Employement:<font style={{ color: "red" }}>*</font> </b></span>
                        <Field component="select" name="category" className="form-control mb-3" onChange={(e)=>{setCategoryValue(e.target.value);clearFunction(e.target.value)}}>
                            <option value="">---Select--</option>
                            {showCategoryMasterAtEmpDeshboard != undefined && showCategoryMasterAtEmpDeshboard?.map((categ_Emp, categ_EmpIndex) => {
                                return (<React.Fragment key={categ_EmpIndex}>
                                    <option value={categ_Emp.category_id}>{categ_Emp.category_name}</option>
                                    </React.Fragment>)
                            })}
                        </Field>
                    <ErrorMessage name="category" component="div" className="text-error" />
                    </jnb.InputGroup>
                </jnb.Col>
                {( showCategoryValue === 5 ||showCategoryValue ==="5" )&& (<>
                    <jnb.Col xs={12} sm={12} md={12} lg={12} xl={4} xxl={4}>
                        <jnb.InputGroup className="mb-4p5">
                            <span className="label-text-style"><b>GPF/CPS No.: </b></span>
                            <Field type="text" name="gpf_cps_no" className="form-control" maxLength="12" onKeyPress={(e) => { allowNumbersOnly(e); }}/>
                        </jnb.InputGroup>
                    </jnb.Col>
                    <jnb.Col xs={12} sm={12} md={12} lg={12} xl={4} xxl={4}>
                        <jnb.InputGroup className="mb-4p5">
                            <span className="label-text-style"><b>APGLI No:</b></span>
                            <Field type="text" name="apgli_no" className="form-control" maxLength="12" onKeyPress={(e) => { allowNumbersOnly(e); }}/>
                        </jnb.InputGroup>
                    </jnb.Col>
                    <jnb.Col xs={12} sm={12} md={12} lg={12} xl={4} xxl={4}>
                        <jnb.InputGroup className="mb-4p5">
                            <span className="label-text-style"><b>GIS Category:</b></span>
                            <Field type="text" name="gis_category" className="form-control" maxLength="12" onKeyPress={(e) => { allowNumbersOnly(e); }}/>
                        </jnb.InputGroup>
                    </jnb.Col>
                </>)}
                {(showCategoryValue === 1 || showCategoryValue ==="1") && (<>
                <jnb.Col xs={12} sm={12} md={12} lg={12} xl={4} xxl={4}>
                        <jnb.InputGroup className="mb-4p5">
                            <span className="label-text-style"><b>UAN No: </b></span>
                            <Field type="text" name="uan_no" className="form-control" maxLength="12" onKeyPress={(e) => { allowNumbersOnly(e); }}/>
                        </jnb.InputGroup>
                    </jnb.Col>
                    <jnb.Col xs={12} sm={12} md={12} lg={12} xl={4} xxl={4}>
                        <jnb.InputGroup className="mb-4p5">
                            <span className="label-text-style"><b>PF No:</b></span>
                            <Field type="text" name="pf_no" className="form-control" maxLength="12" onKeyPress={(e) => { allowNumbersOnly(e); }}/>
                        </jnb.InputGroup>
                    </jnb.Col>
                    </>)}
                                            <jnb.Col xs={12} sm={12} md={12} lg={12} xl={4} xxl={4}>
                                                <jnb.InputGroup className="mb-4p5">
                                                    <span className="label-text-style"><b>Total Experience in APCFSS:<font style={{ color: "red" }}>*</font> </b></span>
                                                    <span><b style={{ color: "darkgreen" }}>{showTotalExperienceInYears} Years {showTotalExperienceInMonths}  Months</b></span>
                                                </jnb.InputGroup>
                                            </jnb.Col>
                                            <jnb.Col xs={12} sm={12} md={12} lg={12} xl={4} xxl={4}>
                                                <jnb.InputGroup className="mb-4p5">
                                                    <span className="label-text-style"><b>Renewal Date:</b></span>
                                                    <Field type="date" name="renewaldate" className="form-control" />
                                                </jnb.InputGroup>
                                            </jnb.Col>
                                    
                                            <jnb.Col xs={12} sm={12} md={12} lg={12} xl={4} xxl={4}>
                                                <jnb.InputGroup className="mb-4p5">
                                                    <span className="label-text-style"><b>Zone:<font style={{ color: "red" }}>*</font> </b></span>
                                                    <Field component="select" name="zoneId" className="form-control mb-3"
                                                        onChange={(e) => { GetdesignationsdataAtDahboard(e.target.value); }}  disabled={true}>
                                                        <option value="">---Select--</option>
                                                        {showZoneMasterAtEmpDeshboard != undefined && showZoneMasterAtEmpDeshboard?.map((datas, zone_index) => {
                                                            return (<React.Fragment key={zone_index}>
                                                                <option key={zone_index} value={datas.zone_id}>
                                                                    {datas.zone_name}
                                                                </option>
                                                            </React.Fragment>);
                                                        })}
                                                    </Field>
                                                    <ErrorMessage name="zoneId" component="div" className="text-error" />
                                                </jnb.InputGroup>
                                            </jnb.Col>
                                            <jnb.Col xs={12} sm={12} md={12} lg={12} xl={4} xxl={4}>
                                                <jnb.InputGroup className="mb-4p5">
                                                    <span className="label-text-style"><b>Designation:<font style={{ color: "red" }}>*</font> </b></span>
                                                    <Field component="select" name="designationId" className="form-control mb-3"  disabled={true}>
                                                        <option value="">---Select--</option>
                                                        {showDesignationsDataAtEmpDeshboard != undefined && showDesignationsDataAtEmpDeshboard?.map((desig_dataEmp, desig_dataIndex) => {
                                                            return (<React.Fragment key={desig_dataIndex}>
                                                                <option value={desig_dataEmp.designation_id}>{desig_dataEmp.designation_name}</option>
                                                            </React.Fragment>)
                                                        })}
                                                    </Field>
                                                    <ErrorMessage name="designationId" component="div" className="text-error" />
                                                </jnb.InputGroup>
                                            </jnb.Col>
                                            <jnb.Col xs={12} sm={12} md={12} lg={12} xl={4} xxl={4}>
                                                <jnb.InputGroup className="mb-4p5">
                                                    <span className="label-text-style"><b>RO Emp:<font style={{ color: "red" }}>*</font> </b></span>
                                                    <Field component="select" name="roEmpId" className="form-control mb-3">
                                                        <option value="">---Select--</option>
                                                        {showZoneMasterAtEmpDeshboard != undefined && showZoneMasterAtEmpDeshboard?.map((depat_Emp, emp_index) => {
                                                            return (<React.Fragment key={emp_index}>
                                                                <option value={depat_Emp.emp_id}>{depat_Emp.emp_id}-{depat_Emp.emp_name}</option>
                                                            </React.Fragment>)
                                                        })}
                                                    </Field>
                                                    <ErrorMessage name="roEmpId" component="div" className="text-error" />
                                                </jnb.InputGroup>
                                            </jnb.Col>
                                          
                                            <jnb.Col xs={12} sm={12} md={12} lg={12} xl={4} xxl={4}>
                                                <jnb.InputGroup className="mb-4p5">
                                                    <span className="label-text-style"><b>Department:<font style={{ color: "red" }}>*</font> </b></span>
                                                    <Field component="select" name="department" className="form-control mb-3">
                                                        <option value="">---Select--</option>
                                                        {showDepartmentMasterApEmpDashboard != undefined && showDepartmentMasterApEmpDashboard?.map((depMaster, depMasterIndex) => {
                                                            return (<React.Fragment key={depMasterIndex}>
                                                                <option value={depMaster.department_id}>{depMaster.department_name}</option>
                                                            </React.Fragment>)
                                                        })}
                                                    </Field>
                                                    <ErrorMessage name="department" component="div" className="text-error" />
                                                </jnb.InputGroup>
                                            </jnb.Col>
                                         
                                            <jnb.Col xs={12} sm={12} md={12} lg={12} xl={4} xxl={4}>
                                                <jnb.InputGroup className="mb-4p5">
                                                    <span className="label-text-style"><b>Level:<font style={{ color: "red" }}>*</font> </b></span>
                                                    <Field component="select" name="level" className="form-control mb-3">
                                                        <option value="">---Select--</option>
                                                        {showLevelsMasterAtEmpDashboard != undefined && showLevelsMasterAtEmpDashboard?.map((leaveMasteroptions, leaveMasterIndex) => {
                                                            return (<React.Fragment key={leaveMasterIndex}>
                                                                <option value={leaveMasteroptions.level_id}>{leaveMasteroptions.level_name}</option>
                                                            </React.Fragment>)
                                                        })}
                                                    </Field>
                                                    <ErrorMessage name="level" component="div" className="text-error" />
                                                </jnb.InputGroup>
                                            </jnb.Col>
                                            <jnb.Col xs={12} sm={12} md={12} lg={12} xl={4} xxl={4}>
                                                <jnb.InputGroup className="mb-4p5">
                                                    <span className="label-text-style"><b>ProjectWise:<font style={{ color: "red" }}>*</font> </b></span>
                                                    <Field component="select" name="projectwise" className="form-control mb-3">
                                                        <option value="">---Select--</option>
                                                        {showProjectWiseMasterAtEmpDashboard != undefined && showProjectWiseMasterAtEmpDashboard?.map((projectMasteroptions, projectMasterIndex) => {
                                                            return (<React.Fragment key={projectMasterIndex}>
                                                                <option value={projectMasteroptions.project_id}>{projectMasteroptions.project_name}</option>
                                                            </React.Fragment>)
                                                        })}
                                                    </Field>
                                                    <ErrorMessage name="projectwise" component="div" className="text-error" />
                                                </jnb.InputGroup>
                                            </jnb.Col>
                                            <jnb.Col xs={12} sm={12} md={12} lg={12} xl={4} xxl={4}>
                                                <jnb.InputGroup className="mb-4p5">
                                                    <span className="label-text-style"><b>Band:<font style={{ color: "red" }}>*</font> </b></span>
                                                    <Field component="select" name="band" className="form-control mb-3">
                                                        <option value="">---Select--</option>
                                                        {showBandMasterAtEmpDashboard != undefined && showBandMasterAtEmpDashboard?.map((banddata, bandIndex) => {
                                                            return (<React.Fragment key={bandIndex}>
                                                                <option value={banddata.band_id}>{banddata.band_name}</option>
                                                            </React.Fragment>)
                                                        })}
                                                    </Field>
                                                    <ErrorMessage name="band" component="div" className="text-error" />
                                                </jnb.InputGroup>
                                            </jnb.Col>
                                            <jnb.Col xs={12} sm={12} md={12} lg={12} xl={4} xxl={4}>
                                                <jnb.InputGroup className="mb-4p5">
                                                    <span className="label-text-style"><b>Qualification:<font style={{ color: "red" }}>*</font> </b></span>
                                                    <Field component="select" name="qualification" className="form-control mb-3">
                                                        <option value="">---Select--</option>
                                                        {showQualificationMaster != undefined && showQualificationMaster?.map((dep, i) => {
                                                            return (<React.Fragment key={i}>
                                                                <option value={dep.qualification_id}>{dep.qualification_name}</option>
                                                            </React.Fragment>)}
                                                        )}
                                                    </Field>
                                                    <ErrorMessage name="qualification" component="div" className="text-error" />
                                                </jnb.InputGroup>
                                            </jnb.Col>
                                        </jnb.Row>
                                    </jnb.Card.Body>
                                    <jnb.Modal.Footer>
                                        <jnb.Button variant="warning" onClick={() => closeModelAtEmpDashboard()}>Cancel</jnb.Button>
                                        <jnb.Button variant="primary" type="submit" >Update</jnb.Button>
                                    </jnb.Modal.Footer><></>
                                </jnb.Modal.Body>
                            </form>)}
                    </Formik>
                </jnb.Modal>
            </jnb.Form></FormikProvider>
    </>)
}

