import Swal from "sweetalert2";
import { config } from "./CommonApis";
import { CommonAxiosGet } from "./CommonAxios";


export function viewImage(fileName) {
    // alert(fileName);
    //const dispatch = useDispatch(); file-retrival?fileName=Documnet1_9652268441_document.jpeg

    let url = config.url.COMMON_URL+"file-retrival?fileName="+fileName;
    CommonAxiosGet(url).then((res) => {
        // console.log("res>>>>>>",res)
        if (res.data.status === true) {
            let base64String = res.data.file;
            const [dataType, data] = base64String.split(",");
            const finalFileType = dataType.match(/\/(.+);/)[1];
            
            // console.log("filetype>>>>>>>>>>>>>>>>>>",finalFileType)
            if (finalFileType === "pdf") {
              //  window.open(base64String);
                const newTab = window.open();
           
                newTab.document.body.innerHTML =
                    `<iframe src="${base64String}" width="100%" height="100%"></iframe>`;

                //   const swalpdf = Swal.fire({
                //     html: `<iframe src="${base64String}" width="100%" height="100%"></iframe>`,
                //     width: 'auto',
                //     showCloseButton: true,
                //     showConfirmButton: false,
                //     customClass: {
                //         content: 'custom-modal-content',
                //     },
                // });
                // window.addEventListener('popstate', () => {
                //     swalpdf.close();
                // });
            }
            else if (finalFileType === "text") {
                const newTab = window.open();
                newTab.document.body.innerHTML =
                    `<iframe src="${base64String}" width="100%" height="100%"></iframe>`;
            }
            else {
                const swalPopup = Swal.fire({
                    html: `<img src="${base64String}" class="img-thumbnail" />`,
                    width: '80%',
                    //height:'30%',
                    showCloseButton: true,
                    showConfirmButton: false,
                    customClass: {
                        content: 'custom-modal-content',
                    },
                });
                window.addEventListener('popstate', () => {
                    swalPopup.close();
                });
                //newTab.document.body.innerHTML = `<img src="${base64String}" />`;
            }
        }
        else {
            //  dispatch({ type: 'HIDE_SPINNER', payload: { key: 'showSpinner', value: false } });
            alert("image not found")
        }
    })
}