// roles.js
const roles = {
  // admin: ['dashboard', 'users'],
  // manager: ['dashboard', 'projects'],
  // user: ['dashboard'],
  // HR:['dashboard','homepage','keyperformance','kpsubindicatorsmapping','kpsubindicators','keyperformanceweightage','employeeentryform','hradmindashboard','EmpProfilePrint'],
  // EMPLOYEE:['dashboard','homepage','employeeappraisalform'],
  // IO:['dashboard','homepage','ioappraisalform','EmpProfilePrint'],
  // RO:['dashboard','homepage','roapprisalform','primaryskillmapping','EmpProfilePrint'],

  // dashboard:['HR','EMPLOYEE','IO','RO'],
  homepage:['HR','EMPLOYEE','IO','RO'],
  keyperformance:['HR'],
  kpsubindicatorsmapping:['HR'],
  kpsubindicators:['HR'],
  keyperformanceweightage:['HR'],
  employeeentryform:['HR'],
  hradmindashboard:['HR'],
  appraisalstatusreport:['HR'],
  appraisalrevert:['HR'],
  updateempdetails:['HR'],
  EmpProfilePrint:['HR','IO','RO'],
  employeeappraisalform:['EMPLOYEE'],
  ioappraisalform:['IO'],
  roapprisalform:['RO'],
  primaryskillmapping:['RO'],
  loginwisereport:['IO','RO'],
  // ..................................................................PMS NEW...........................................
  employeepmsform:['EMPLOYEE'],
  iopmsform:['IO'],
  ropmsform:['RO'],
  employeeentryformnew:['HR'],
  mastersentryform:['HR'],
  pmsempprintform:['HR','IO','RO'],
  employeedashboard:['HR'],
  pmsdashboard:['HR'],
 
  //added
  //***********************************************job posts********************************
  jobpostreport:['HR'],
  jobposting:['HR'],

  jobpostingdetails:['EMPLOYEE'],
  JobApplication:['EMPLOYEE'],

  appliedCandidateDetails:['HR'],
  updatestatus:['HR'],

  //***********************************************Leaves********************************
  leavesormovements:['EMPLOYEE'],
  leavesDetailsReport:['EMPLOYEE'],

  
  detailsofleaveapplications:['RO'],
  detailsofleaverequests:['IO'],
   AprroveOrRejectDataHistory:['IO'],
   EmpPreviousHystoryDetails:['RO','IO'],

   TotalLeaveDetailsReport:['HR'], //HR
   EkycOTPVerification:['EMPLOYEE'],
   //***********************************************assets********************************

   employeesinfo:['HR','ADMIN'],
   withoutduesdetails:['HR','ADMIN'],
   duesdetails:['HR','ADMIN'], 
   withduesorwithoutduesdetails:['HR','ADMIN'],
   totalemployeesassetdetails:['HR','ADMIN'],

  //  ******************************************************
   emppaydetails:['HR'],
   infoofpaydetails:['HR'],
   infoofupdatedpaydetails:['HR'],
   dashboradofpaydetils:['HR'],
   payrolls:['HR'],
   payrollsdetails:['HR'],
   govtpayrollsdetails:['HR'],
   payrollsdetailsregular:[ 'HR'],
   payrollsdetailscontract:['HR'],
   payrollsdetailsintern:['HR'],
   payslipdetails:['HR'],
   employeelopdetails:['HR'],
   //***********************************************healthInsuranceDashboard********************************
   // healthInsuranceDashboard:['HR'],
   // healthInsuranceEntryForm:['EMPLOYEE'],
   // healthinsurance:['EMPLOYEE'],
   //***********************************************EKYC********************************
      // EKYC:['EMPLOYEE'],

   //***********************************************FRS********************************
   frsDetails:['HR'],

   detailsofFRSRO:['RO','HR','EMPLOYEE'],
   //***********************************************idcards********************************
   // idcards:['EMPLOYEE'],
 
};

export default roles;
