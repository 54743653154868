import axios from "axios";
import {IMG_DOWNLOAD_URL, config } from "./CommonApis";
import { CommonAxiosGet, CommonAxiosPost, CommonAxiosPostLogin, CommonAxiosPut } from "./CommonAxios";
class CommonPost {
  submitLoginPage(req) {
    return CommonAxiosPost(config.url.COMMON_URL + "jnblogincheck", req);
  }
  kpAreasPost(req) {
    return CommonAxiosPost(config.url.COMMON_URL + "kpsweightage", req);
  }
  kpAreasEmployeepost(req,type) {
    //return CommonAxiosPost(COMMON_URL + "kpemppost", req);
    return CommonAxiosPost(config.url.COMMON_URL + "kpemppostjson/"+type, req);
  }
  iologinPost(req) {
    return CommonAxiosPost(config.url.COMMON_URL + "kpiopost", req);
  }
  rologinPost(req) {
    return CommonAxiosPost(config.url.COMMON_URL + "kpropost", req);
  }
  iofinalPost(req,type) {
    return CommonAxiosPost(config.url.COMMON_URL + "kpfinalstatus/"+type, req);
  }
  kpMasterFormPost(req) {
    return CommonAxiosPost(config.url.COMMON_URL + "keyperformances", req);
  }
  kpUploadPost(req) {
    return CommonAxiosPost(config.url.COMMON_URL + "upload/kpdata", req);
  }
  kpsubindicatorPost(req) {
    return CommonAxiosPost(config.url.COMMON_URL + "keyperformanceindicators", req);
  }
  kpindicatorPost(req) {
    return CommonAxiosPost(config.url.COMMON_URL + "keyperformancesmap", req);
  }
  LoginPost(req) {
    //console.log(req)
    return CommonAxiosPostLogin(config.url.COMMON_URL  +"api/auth/signin", req);
  }
  primaryskillpost(req) {
    return CommonAxiosPost(config.url.COMMON_URL + "employee/primaryskill", req);
  }

  getotppost(mobile) {
    return CommonAxiosPost(config.url.COMMON_URL + "testing/getotp/"+mobile);
  }
  getotpsubmitpost(req) {
    return CommonAxiosPost(config.url.COMMON_URL + "testing/getforgotpassword",req);
  }
empEntryPost(req) {
    return CommonAxiosPost(config.url.COMMON_URL + "saveempdata", req);
  }
  revorteback(emp,actiontype) {
    //console.log("emp,actiontype",emp,actiontype)
    return CommonAxiosPost(config.url.COMMON_URL + "appraisalrevert/"+emp+"/"+actiontype);
  }
  empupdatePost(type,empid,req) {
    return CommonAxiosPost(config.url.COMMON_URL + "employeedataupdate/"+type+"/"+empid,req);
  }
  // .........................................new PermScanWifi.................................
  kpAreasEmployeepostn(req,type) {
    //return CommonAxiosPost(COMMON_URL + "kpemppost", req);
    return CommonAxiosPost(config.url.COMMON_URL + "pms/kpemppostjson/"+type, req);
  }
  MatersEntryPost(name,req) {
    return CommonAxiosPost(config.url.COMMON_URL + "masters/"+name, req);
  }
  employeenewPost(req,type) {
    return CommonAxiosPost(config.url.COMMON_URL + "pms/kpfinalstatus/"+type, req);
  }
  ioPMSFinalStatusPost(req,type) {
    return CommonAxiosPost(config.url.COMMON_URL + "pms/kpiostatus/"+type, req);
  }
  ioPMSFinalStatusPostTest(req,type) {
    return CommonAxiosPost(config.url.COMMON_URL + "pms/kpiostatustest/"+type, req);
  }
  ioPMSFinalStatusPostTest(req,type) {
    return CommonAxiosPost(config.url.COMMON_URL + "pms/kpiostatustest/"+type, req);
  }


  LeaveDetailsSave(req) {
    return CommonAxiosPost(config.url.COMMON_URL + "leave_management_registrationfrs",req);
  }
  RequestAprroveOrRejectedDetailsSave(req) {
    return CommonAxiosPost(config.url.COMMON_URL + "leave_management_ro_updatelist",req);
  }
  laptopDetailsUpdateSave(req) {
    return CommonAxiosPost(config.url.COMMON_URL + "saveLaptopdetails",req);
  }
  assetsDataSave(req) {
    return CommonAxiosPost(config.url.COMMON_URL + "saveassetdetails",req);
  }
  updateassetsData(req) {
    return CommonAxiosPost(config.url.COMMON_URL + "assetreturnupdate",req);
  }
  RegularEmpPaySave(req) {
    return CommonAxiosPost(config.url.COMMON_URL + "saveemployeeregpaydetails",req);
  }
  ContractEmpPaySave(req) {
    return CommonAxiosPost(config.url.COMMON_URL + "saveemployeecontractpaydetails",req);
  }
 InternEmpPaySave(req) {
    return CommonAxiosPost(config.url.COMMON_URL + "saveemployeeinternpaydetails",req);
  }
  GovtEmpPaySave(req) {
    return CommonAxiosPost(config.url.COMMON_URL + "saveemployeegovernmentpaydetails",req);
  }
//modified screens
empEntryPostNew(req) {
  return CommonAxiosPost(config.url.COMMON_URL + "saveempdatanew", req);
}

downloadUpdatedStagephoto1(fileName) {
  // ${INCIDENT_URL}studentIncidentaswodata?slno=${slno}
  return axios.get(`${IMG_DOWNLOAD_URL}${fileName}`, { responseType: "blob", })
}

savepaygeneation(req) {
  return CommonAxiosPost(config.url.COMMON_URL + "savepaygeneation", req);
}

candidateDetailsPost(req) {
  return CommonAxiosPost(config.url.COMMON_URL + "savejobpostingapply",req);
}
jobDetailsPost(req) {
  return CommonAxiosPost(config.url.COMMON_URL + "savejobposting",req);
}
saveinterviewdlsPost(req) {
  return CommonAxiosPost(config.url.COMMON_URL + "saveinterviewdls",req);
}
saveHealthInsuranceEntrySave(req) {
  return CommonAxiosPost(config.url.COMMON_URL + "health_insurance",req);
}
finalsave(levels_interview,final_status,final_status_remarks,final_status_inserted_by,job_appl_id) {
  // /updatefinalstatus/{levels_interview}/{final_status}/{final_status_remarks}/{final_status_inserted_by}/{job_appl_id}
  return CommonAxiosPost(config.url.COMMON_URL + "updatefinalstatus/"+levels_interview+"/"+final_status+"/"+final_status_remarks+"/"+final_status_inserted_by+"/"+job_appl_id);
}
EmpEKYCPost(req) {
  return CommonAxiosPost(config.url.COMMON_URL + "saveaadhardata", req);
}

// http://172.16.117.100:8080/cfsshrms/health_insurance_save
saveHealthInsuranceSave(req) {
  return CommonAxiosPost(config.url.COMMON_URL + "health_insurance_save",req);
}
}
export default new CommonPost();