import { FormikProvider, useFormik, Form } from "formik";
import { Link, useNavigate } from "react-router-dom";
import * as jnb from "react-bootstrap";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { config } from "../../../CommonUtils/CommonApis";
import { CommonAxiosGet } from "../../../CommonUtils/CommonAxios";
import moment from 'moment';

export default function LeavesDetailsReport() {

    const [GetLeaveReportDetails, setLeaveReportDetails] = useState();
    const [showErrMsg, setErrMsg] = useState(false)
    const logindetails = useSelector((state) => state?.reducers?.loginreducer?.userLoginDetials);

    const [search, setSearch] = useState("");
    const navigate = useNavigate();
    const formIk = useFormik({
        enableReinitialize: true,
        initialValues: {},
        onSubmit: (values) => {},
    });

    function GetEmpLeaveHistory() {
        let Url = (config.url.COMMON_URL + "leavesdataFrs/" + logindetails?.username);
        CommonAxiosGet(Url).then((res) => {
            if (res?.data?.leavesdataFrsEmpStatus === true) {
                setLeaveReportDetails(res?.data?.leavesdataFrsEmp);
                setErrMsg(false)
            }
            else {
                setLeaveReportDetails([]);
                setErrMsg(true)
            }
        })
    }
    const fetchData = useRef(false)
    useEffect(() => {
        if (!fetchData.current) {
            GetEmpLeaveHistory()
            fetchData.current = true
        }
        // eslint-disable-next-line
    }, []);

    function NavigateToJobPostEntryform(empid) {
        navigate("/leavesormovements", { state: { empid: empid } });
        localStorage.setItem("empid", empid);
    }

    return (<>
        <jnb.Row className=" m-0">
            <jnb.Col xs={12} sm={12} md={12} lg={10} xl={10} xxl={10}>
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to="/homepage">Home</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">Leave Status</li>
                </ol>
            </jnb.Col>
        </jnb.Row>

        <jnb.Row className=" pt-2">
            <jnb.Row className="m-0 pt-2">
                <jnb.Col xs={12} sm={12} md={12} lg={10} xl={10} xxl={10}>
                    <div className="head-text" style={{ display: "inline" }}>
                        <h1> Leave Status of {logindetails?.employee_name}</h1> </div>
                </jnb.Col>
                <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                    <jnb.InputGroup>
                        <input className="form-control" id="search" name={`search_${Date.now()}`} type="text" placeholder="search" autoComplete="off"
                            onChange={event => setSearch(event.target.value)}
                        />
                    </jnb.InputGroup>
                </jnb.Col>
                <jnb.Col xs={12} sm={12} md={12} lg={7} xl={7} xxl={7}></jnb.Col>
                <jnb.Col xs={12} sm={12} md={12} lg={2} xl={2} xxl={2} className="pe-0">
                    <button type="button" class="btn btn-success  btn-sm float-end"
                        onClick={() => { NavigateToJobPostEntryform(logindetails?.username) }}>Apply For Leave</button>
                </jnb.Col>
            </jnb.Row>
            <FormikProvider value={formIk}>
                <Form onSubmit={formIk.handleSubmit} onChange={formIk.handleChange}>
                    <jnb.Row className="px-3 pt-2">
                        {GetLeaveReportDetails !== undefined && GetLeaveReportDetails?.length > 0 && GetLeaveReportDetails !== "No data found" ? (<>
                            <table className="table  table-bordered  table-responsive leaveapproval-table">
                                <thead>
                                    <tr>
                                        <th align="center" style={{ width: "1%" }}>S.No</th>
                                        <th>Leave Request</th>
                                        <th>Details of <br></br>Leaves for Apply</th>
                                        <th>Type of Leave</th>
                                        <th>Visiting Office Name - Remarks</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody style={{ textAlign: "left" }}>
                                    {GetLeaveReportDetails && GetLeaveReportDetails !== undefined && GetLeaveReportDetails?.filter((element) => {
                                        const typeOfLeave = element?.type_of_leave?.toLowerCase() === "movement" ? "regularization" : element?.type_of_leave?.toLowerCase();
                                        return (
                                            typeOfLeave?.includes(search.toLowerCase()) ||
                                            element?.from_date?.toString().toLowerCase().includes(search.toLowerCase()) ||
                                            element?.to_date?.toString().toLowerCase().includes(search.toLowerCase()) ||
                                            element?.type_name?.toLowerCase().includes(search.toLowerCase())
                                        );
                                    })?.map((data, i) => {
                                        const rowClassNameAtleave = data?.type_of_leave === "Leave"
                                        ? 'RowColorForLeave'
                                        : data?.type_of_leave === "Movement"
                                            ? 'RowColorForMovement'
                                            : data?.type_of_leave === "WFH"
                                                ? 'RowColorForWEH'
                                                : '';
                                        return (<React.Fragment key={i}>
                                            <tr className={rowClassNameAtleave}>
                                                <td>{i + 1}</td>
                                                <td>{data?.type_of_leave === "Movement" ? <>Regularization</> : <>{data?.type_of_leave}</>}</td>
                                                <td>
                                                    {data?.no_of_days !== "" ? (<>
                                                        {data?.no_of_days === "One Day" && (<>
                                                            {moment(data?.from_date, 'YYYY-MM-DD').format('DD-MM-YYYY')}

                                                        </>)}{data?.no_of_days === "More Than One Day" && (<>
                                                            {moment(data?.from_date, 'YYYY-MM-DD').format('DD-MM-YYYY')} &nbsp;to&nbsp; {
                                                                moment(data?.to_date, 'YYYY-MM-DD').format('DD-MM-YYYY')}</>)}
                                                        {data?.no_of_days === "One Half Day" && (<>
                                                            {moment(data?.from_date, 'YYYY-MM-DD').format('DD-MM-YYYY')} ({data?.halfday_from_time_to_time})</>)}
                                                    </>) : (<>
                                                    </>)}
                                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                                </td>
                                                <td>
                                                    {data?.type_name === "Movement" ? <>Regularization</> : <>{data?.type_name}</>}</td>
                                                <td>
                                                    {(data?.type_of_leave === "Leave" || data?.type_of_leave === "WFH" || data?.type_of_leave === "Movement") ? (<>
                                                        <td>{data?.office_name}<pre></pre>{data?.remarks}</td>
                                                    </>) : (<td></td>)}
                                                </td>
                                                <td>
                                                    {data?.leave_final_status !== null ? (<>
                                                        {data?.leave_final_status === "Approved" && (<>
                                                            <b style={{ color: "green" }}>Approved</b></>)}
                                                        {data?.leave_final_status === "Rejected" && (<><b style={{ color: "red" }}>Rejected</b>&nbsp;-<b>{data?.leave_status_remakrs}</b></>)}
                                                        {data?.leave_final_status === "pending" && (<><b >  Pending for Approval</b> </>)}
                                                    </>) : (<></>)}
                                                </td>
                                            </tr>
                                        </React.Fragment>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </>) :(showErrMsg && (<center className="mt-5"><b style={{ color: "red" }}>*********No Data Found*********</b></center>))

                            }
                    </jnb.Row>
                </Form>
            </FormikProvider>
        </jnb.Row>
    </>)
}
