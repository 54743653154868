
import { ErrorMessage, Field, Form, FormikProvider, useFormik } from 'formik';
import React, { useEffect, useRef, useState } from 'react'
import * as jnb from "react-bootstrap";
import { Link } from 'react-router-dom'
import { config } from '../../../../CommonUtils/CommonApis';
import { CommonAxiosGet } from '../../../../CommonUtils/CommonAxios';
import CommonReactTable from '../../../../CommonUtils/CommonReactTable';
import { InfoAlert } from '../../../../CommonUtils/SweetAlerts';
import * as Yup from "yup"
import moment from 'moment';
import { useSelector } from 'react-redux';
export default function EmpFRSReport() {
    const frshrlogindetails = useSelector((frsstate) => frsstate?.reducers?.loginreducer?.userLoginDetials);
    const [showemployeedetails, setEmployeeDetails] = useState([]);
    const [showErrMsg,setErrMsg]=useState(false);
    const [showTable, setTable] = useState(false);

    const formIk = useFormik({
        enableReinitialize: true,
        initialValues: {from_date:"",to_date:""},
        validationSchema:Yup.object().shape({
            from_date:Yup.string().required("Required"),
            to_date:Yup.string().required("Required")
        }), 
        onSubmit: (values) => {console.log(null)},
    });



    function GetData(fromdate,todate) {
        if(!fromdate || !todate){
            InfoAlert("From Date and To Data Required",'warning')
        }
        else{
        
        let Url = config.url.COMMON_URL+"employeeRegistration/attandancereport/"+fromdate+"/"+todate;
                CommonAxiosGet(Url).then((res) => {
                    if (res?.data?.status === true) {
                        setEmployeeDetails(res?.data?.attandancereport);
                        setErrMsg(false);
                        setTable(true)
                    }
                    else {
                        setEmployeeDetails([]);
                        setErrMsg(true)
                        setTable(true)
                    }
                })
            }
    }
    function GetToDate(date) {
        const fromDate = new Date(date);
        const nextDay = new Date(fromDate);
        nextDay.setDate(fromDate.getDate() + 1);
        const toDateMin = nextDay.toISOString().split("T")[0];
        const toDateInput = document.getElementsByName("to_date")[0];
        if (toDateInput) {
            toDateInput.min = toDateMin;
        }
    }
    const columns = [
        {
            Header: "S.No",
            accessor: (row, i) => (<>{i + 1}&nbsp;</>),
            Cell: ({ value }) => <div style={{ textAlign: 'center' }}>{value}</div>,
        },
        {
            Header: 'ID',
            accessor: "cfms_id",
        },
        {
            Header: 'Employee Name',
            accessor: "emp_name",
        },
        {
            Header: 'Date',
            accessor: "att_date",
            Cell: ({ value,row }) => <div style={{ width: '50%' }}>{moment(row?.original?.att_date, 'YYYY-MM-DD').format('DD-MM-YYYY')}</div>,
        },
      
        {
            Header: 'Clock In',
            accessor: "attendance_in",
        },
        {
            Header: 'Clock out',
            accessor: "attendance_out",
        },
        {
            Header: 'Premises',
            accessor: row => (<div style={{textAlign:"center"}}>{row?.in_lat_long}-{row?.out_lat_long}</div>)
        },
       
];
    return (
        <div>
            <jnb.Row className=" m-0">
                <jnb.Col xs={12} sm={12} md={12} lg={11} xl={11} xxl={11}>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to="/homepage">Home</Link>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">FRS Report</li>
                    </ol>
                </jnb.Col>
               
            </jnb.Row>
            <jnb.Row className=" pt-2">
                <FormikProvider value={formIk}>
                    <Form onSubmit={formIk.handleSubmit} onChange={formIk.handleChange} autoComplete="off"  >
                        <div className="border p-3 pb-3 mb-4">
                        <jnb.Row className="px-3">
                        <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                                        <jnb.InputGroup className="mb-1">
                                            <label className="w-100 mb-2"><b>From Date </b></label>
                                            <Field type="date" name="from_date" 
                                            className="form-control" max={new Date().toISOString().split("T")[0]}
                                                onChange={(e) => {
                                                formIk.setFieldValue("to_date", "");GetToDate(e.target.value);setTable(false)
                                            }}/>
                                        </jnb.InputGroup>
                                        <ErrorMessage component="div" className="text-danger" name="from_date" />
                                    </jnb.Col>
                                    <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                                        <jnb.InputGroup className="mb-1">
                                            <label className="w-100 mb-2"><b>To Date </b></label>
                                            <Field type="date" name="to_date" className="form-control" onChange={(e) => {
                                                setTable(false)}}max={new Date().toISOString().split("T")[0]}/>
                                        </jnb.InputGroup>
                                        <ErrorMessage component="div" className="text-danger" name="to_date" />
                                    </jnb.Col>
                                    <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                                      <button type="button" className='btn btn-success mt-4' onClick={()=>{
                                            GetData(formIk?.values?.from_date,formIk?.values?.to_date)
                                      }}>Get Details</button>
                                    </jnb.Col>
                                    </jnb.Row>
                                    </div>
                                    <jnb.Row className="px-3 pt-2">
                                    {showTable === true ? (<>
                            {showemployeedetails?.length>0 ? (<>
                            <CommonReactTable data={showemployeedetails} columns={columns} showFooter="false" filename="FRS Report" 
                                        headerName={`FRS Report`} />
                            </>):(<>{showErrMsg === true ? (<>
                                <center><b style={{color:"red"}}>*********************No Data Found******************* </b></center></>):(<></>)}</>)}
                                </>):(<></>)}
                        </jnb.Row>
                    </Form>
                </FormikProvider>
            </jnb.Row>
        </div>
    ) 
}

