import { ErrorMessage, Field, Form, FormikProvider, useFormik } from 'formik';
import React, { useEffect, useRef, useState } from 'react'
import * as jnb from "react-bootstrap";
import { Link } from 'react-router-dom'
import { CommonAxiosGet } from '../../../../CommonUtils/CommonAxios';
import { InfoAlert } from '../../../../CommonUtils/SweetAlerts';
import * as Yup from "yup"
import { useSelector } from 'react-redux';
import { config } from '../../../../CommonUtils/CommonApis';
import { useReactToPrint } from 'react-to-print';
import SearchAndExportSection from '../../CommonPages/SearchAndExportSection';
export default function FRSDetailsReportRo() {
    const frslogindetails = useSelector((frsstate) => frsstate?.reducers?.loginreducer?.userLoginDetials);
    console.log(frslogindetails?.rolesFrs[0])
    const [showFrsemployeedetails, setFrsEmployeeDetails] = useState([]);
    const [showFrsemployeeSameDaydetails, setFrsEmployeeSameDayDetails] = useState([]);
    const [showtotalsSameDay, settotalsSameDay] = useState([]);
    const[showunitmstDetails,setunitmstDetails]=useState([])
    const [showfrsErrMsg, setfrsErrMsg] = useState(false);
    const [showErr1Msg, setErr1Msg] = useState(false);
    const [showFrsTable, setFrsTable] = useState(false);
    const [showSameDayDataTable, setSameDayDataTable] = useState(false);
    const [showFilterFrsEmployeeSameDayDetails,setFilterFrsEmployeeSameDayDetails]=useState([])
    const [dateRange, setDateRange] = useState([]);
    const [frssearchQuery, setfrsSearchQuery] = useState('');

    const [searchQuery1, setSearchQuery1] = useState('');
    const frsformIk = useFormik({
        enableReinitialize: true,
        initialValues: { 
            frs_from_date: "", 
            frs_to_date: "",
            unit_mst:"0" },
        validationSchema: Yup.object().shape({
            frs_from_date: Yup.string().required("Required"),
            frs_to_date: Yup.string().required("Required")
        }),
        onSubmit: (values) => {console.log(null) },
    });

    function GetDataInFRS(frsfromdate, frstodate,unitMst) {
        if (!frsfromdate || !frstodate) {
            InfoAlert("From Date and To Date Required", 'warning')
        }
        else {
            let conditionURL = "";
            
            if (frslogindetails?.username === "hradmin") {
                conditionURL = config.url.COMMON_URL + "employeeRegistration/attandancereportRo/" + frsfromdate + "/" + frstodate + "/hradmin/" +unitMst;
            } else if(frslogindetails?.rolesFrs[0]?.role_ro  === "RO"){
                conditionURL = config.url.COMMON_URL + "employeeRegistration/attandancereportRo/" + frsfromdate + "/" + frstodate + "/ro/" +frslogindetails?.username;
            }
            else if(frslogindetails?.rolesFrs[0]?.role_emp === "EMPLOYEE"){
                conditionURL = config.url.COMMON_URL + "employeeRegistration/attandancereportRo/" + frsfromdate + "/" + frstodate + "/employee/" +frslogindetails?.username;
            }
            CommonAxiosGet(conditionURL).then((res) => {
                if (res?.data !== "No data Found") {
                    setFrsEmployeeDetails(res?.data);
                    setfrsErrMsg(false);
                    setFrsTable(true)
                    setDateRange(generateDateHeaders(frsfromdate, frstodate));
                    setSameDayDataTable(false)
                }
                else {
                    setFrsEmployeeDetails([]);
                    setfrsErrMsg(true)
                    setFrsTable(true)
                    setSameDayDataTable(false)
                }
            })
        }
    }
    function GetTodayOrSameDayData(frssamedayfromdate,samedayUnit) {
        let sameday = "";
        if (frslogindetails?.username === "hradmin") {
            sameday = config.url.COMMON_URL + "employeeRegistration/attandanceleavereport/" + frssamedayfromdate +  "/hradmin/"+samedayUnit
        } else{
            sameday = config.url.COMMON_URL + "employeeRegistration/attandanceleavereport/" + frssamedayfromdate + "/ro/"+ frslogindetails?.username;
        }
        CommonAxiosGet(sameday).then((res) => {
            if (res?.data?.attandanceLeavereportStatus === true) {
                setFrsEmployeeSameDayDetails(res?.data?.attandanceLeavereport);
                setErr1Msg(false);
                setFrsTable(false)
            }
            else {
                setFrsEmployeeSameDayDetails([]);
                setErr1Msg(true)
                setFrsTable(false)
            }

            if (res?.data?.attandanceLeavereportcountStatus === true) {
                settotalsSameDay(res?.data?.attandanceLeavereportcount);
            }
            else {
                settotalsSameDay([]);
            }
        })
    }
    function GetUnitsDetails() {
        let Url = config.url.COMMON_URL + "employeeRegistration/getunits"
        CommonAxiosGet(Url).then((res) => {
            if (res?.data?.status === true) {
                setunitmstDetails(res?.data?.unit_mst);
            }
            else {
                setunitmstDetails([]);
            }
        })
    }
    function GetFRSToDate(getfrsdate) {
        const frsgetfromDate = new Date(getfrsdate);
        const frsgettoDateMin = frsgetfromDate?.toISOString().split("T")[0];
        const toDateInputinfrs = document?.getElementsByName("frs_to_date")[0];
        if (toDateInputinfrs) {
            toDateInputinfrs.min = frsgettoDateMin;
        }
    }
    const generateDateHeaders= (headersfromDate, headerstoDate) => {
        const startDateheader = new Date(headersfromDate);
        const endDateheader = new Date(headerstoDate);
        const frstableheaders = [];
    
        for (let date1 = new Date(startDateheader); date1 <= endDateheader; ) {
            const formattedDate = date1.toISOString().split('T')[0];
            frstableheaders.push(formattedDate);
    
            date1 = new Date(date1.setDate(date1.getDate() + 1));
        }
    
        return frstableheaders;
    };

    const formatEmployeeData= (frsempdata, frsempheaders) => {
        const formatDateForKey = (formatedate) => formatedate.replace(/-/g, '_');

        return frsempheaders?.map(frsformatedate => {
            const formattedFrsDate = formatDateForKey(frsformatedate);

            return {
                frsformatedate,
                in: frsempdata[`in_${formattedFrsDate}`] || "",
                out: frsempdata[`out_${formattedFrsDate}`] || "",
                hours: frsempdata[`diff_${frsformatedate.replace(/-/g, '_')}`] || "",
                holiday: frsempdata[`h_${frsformatedate.replace(/-/g, '_')}`] || "",
                frsleaves: frsempdata[`l_${frsformatedate.replace(/-/g, '_')}`] || "",
                 lop: frsempdata[`lop_${frsformatedate.replace(/-/g, '_')}`] || ""
            };
        });
    };
    const getBackgroundColor = (hoursforcolors) => {
        if (!hoursforcolors || typeof hoursforcolors !== 'string') return {};

        const [hoursValueforclrs] = hoursforcolors?.split(':').map(Number);

        if (isNaN(hoursValueforclrs)) {
            return {};
        }
        if (hoursValueforclrs >= 8) {
            return { backgroundColor: "lightgreen" };
        } else if (hoursValueforclrs >= 7) {
            return { backgroundColor: "yellow" };
        } else {
            return { backgroundColor: "lightcoral" };
        }
    };

    const getDayWithSuffix = (day) => {
        if (day > 3 && day < 21) return `${day}th`; 
        switch (day % 10) {
            case 1: return `${day}st`;
            case 2: return `${day}nd`;
            case 3: return `${day}rd`;
            default: return `${day}th`;
        }
    };
    
    const formatDateToCustomHeader = (dateString) => {
        const [year, month, day] = dateString.split('-');
        const date = new Date(`${year}-${month}-${day}`);
        const dayWithSuffix = getDayWithSuffix(parseInt(day, 10));
        const monthName = date.toLocaleString('default', { month: 'short' });
        return `${dayWithSuffix} ${monthName}`;
    };
    let frscomponentRef = useRef();
    const frshandleprint = useReactToPrint({
        content: () => frscomponentRef.current,
        documentTitle: "FRS Report",
    });
        useEffect(() => {
            if(frslogindetails?.username === "hradmin"){
                GetUnitsDetails()
            }else{return;}
        }, []);

    function GetTypeOfCasesDetails(filterType) {
        let filteredData = [];
        
        if (filterType === "in_office") {
            filteredData = showFrsemployeeSameDaydetails?.filter(item => item.in_office === "Y");
        } else if (filterType === "on_duty") {
            filteredData = showFrsemployeeSameDaydetails?.filter(item => item.on_duty=== "Y");

        } else if (filterType === "leave_status") {
            filteredData = showFrsemployeeSameDaydetails?.filter(item => item.leave_status=== "1");

        } else if (filterType === "others") {
            filteredData = showFrsemployeeSameDaydetails?.filter(item => item.in_office === "N" && item.on_duty === "N" && item.leave_status ==="0");

        }
        if (filteredData?.length > 0) {
            setFilterFrsEmployeeSameDayDetails(filteredData);
            setSameDayDataTable(true);
        } else {
            InfoAlert("No data found for this filter", 'warning');
            setFilterFrsEmployeeSameDayDetails([]);
            setSameDayDataTable(false);
        }
    }
    return (
        <div>
            <jnb.Row className="m-0">
                <jnb.Col xs={12} sm={12} md={12} lg={11} xl={11} xxl={11}>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to="/homepage">Home</Link>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">FRS Report</li>
                    </ol>
                </jnb.Col>
            </jnb.Row>
            <jnb.Row className=" pt-2">
                <FormikProvider value={frsformIk}>
                    <Form onSubmit={frsformIk?.handleSubmit} onChange={frsformIk?.handleChange} autoComplete="off"  >
                        <div className="border px-3 pb-3 mb-4 pt-1">
                            <jnb.Row className="px-3">
                                <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                                    <jnb.InputGroup className="mb-1">
                                        <label className="w-100 mb-2"><b>From Date </b></label>
                                        <Field type="date" name="frs_from_date"
                                            className="form-control" max={new Date().toISOString().split("T")[0]}
                                            onChange={(e) => {
                                                frsformIk?.setFieldValue("frs_to_date", ""); GetFRSToDate(e?.target?.value); setFrsTable(false);
                                                setSameDayDataTable(false); setFrsEmployeeSameDayDetails([]);setSearchQuery1('');setfrsSearchQuery('')
                                                setFrsEmployeeDetails([]); settotalsSameDay([]);
                                            }} />
                                    </jnb.InputGroup>
                                    <ErrorMessage component="div" className="text-danger" name="frs_from_date" />
                                </jnb.Col>
                                <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                                    <jnb.InputGroup className="mb-1">
                                        <label className="w-100 mb-2"><b>To Date </b></label>
                                        <Field type="date" name="frs_to_date" className="form-control" onChange={(e) => {
                                            setFrsTable(false); setFrsEmployeeDetails([]); settotalsSameDay([]);
                                            setSameDayDataTable(false); setFrsEmployeeSameDayDetails([]); setSearchQuery1('');setfrsSearchQuery('')
                                        }} max={new Date().toISOString().split("T")[0]} />
                                    </jnb.InputGroup>
                                    <ErrorMessage component="div" className="text-danger" name="frs_to_date" />
                                </jnb.Col>
                                {frslogindetails?.username === "hradmin" && (
                                <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                                <jnb.InputGroup className="mb-1">
                                <label className="w-100 mb-2"><b>Unit</b></label>
                                    <Field as="select" className="form-control mb-2" name="unit_mst"
                                    onChange={(e) => {
                                        setFrsTable(false); setFrsEmployeeDetails([]);settotalsSameDay([]);
                                        setSameDayDataTable(false); setFrsEmployeeSameDayDetails([]);setSearchQuery1('');setfrsSearchQuery('')
                                    }}>
                                        <option value="">--Search here--</option>
                                        <option value="0">ALL</option>
                                        {showunitmstDetails?.map((unitdata, i) => (
                                            <option key={i} value={unitdata?.unit_id}>{unitdata?.unit_desc}</option>))}
                                    </Field>
                                </jnb.InputGroup>

                                </jnb.Col>
                                )}
                               
                                <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                                    <button type="button" className='btn btn-success mt-4' onClick={() => {
                                        const fromDateInfrs = new Date(frsformIk?.values?.frs_from_date);
                                        const toDateInFrs = new Date(frsformIk?.values?.frs_to_date);
                                        if (!frsformIk?.values?.frs_from_date || !frsformIk?.values?.frs_to_date) {
                                            InfoAlert("From Date and To Date are required", 'warning');
                                            return;
                                        }
                                        if (toDateInFrs < fromDateInfrs) {
                                            InfoAlert("To Date cannot be earlier than From Date", 'warning');
                                        } else {
                                             const frsdiffTime = (toDateInFrs - fromDateInfrs); 
                                             const frsdiffDays = (frsdiffTime / (1000 * 60 * 60 * 24)) + 1;

                                            if (frsdiffDays > 62) {
                                                InfoAlert("The date range should not exceed 62 days", 'warning');
                                            } else {
                                                if (frsformIk?.values?.frs_from_date === frsformIk?.values?.frs_to_date) {
                                                    GetTodayOrSameDayData(frsformIk?.values?.frs_from_date,frsformIk?.values?.unit_mst)
                                                }
                                                else {
                                                    GetDataInFRS(frsformIk?.values?.frs_from_date, frsformIk?.values?.frs_to_date,frsformIk?.values?.unit_mst);
                                                }
                                            }
                                        }
                                    }}>Get Details</button>
                                </jnb.Col>
                            </jnb.Row>
                        </div>
                        {showFrsTable === true && showFrsemployeedetails?.length > 0 && showFrsemployeedetails !== "No data Found" ? (
                            <SearchAndExportSection
          searchValue={frssearchQuery}
          onSearchChange={(e) => setfrsSearchQuery(e.target.value)}
          placeholder="Search here ID or Name or Unit"
          handlePrint={frshandleprint}
        />
                           
                        ) : ""}
                       {showtotalsSameDay?.length>0 && (<>
                            <div className='frsSameDateDiv'>
                                { showtotalsSameDay?.map((data, i) => (
                                    <div key={i} className='showtotalsSameDayDiv'>
                                        <div className='div-for-typeOfFRS'>
                                            <b>Present:</b> <b className='present-Frs' 
                                            onClick={()=>{GetTypeOfCasesDetails("in_office");setSearchQuery1('');setfrsSearchQuery('')}}>{data?.in_office}</b>
                                        </div>
                                        <div className='div-for-typeOfFRS'>
                                            <b>On Duty:</b> <b className='onDuty-Frs' 
                                            onClick={()=>{GetTypeOfCasesDetails("on_duty");setSearchQuery1('');setfrsSearchQuery('')}}>{data?.on_duty}</b>
                                        </div>
                                        <div className='div-for-typeOfFRS'>
                                            <b>Leave:</b> <b className='leave-Frs' onClick={()=>{GetTypeOfCasesDetails("leave_status");
                                            setSearchQuery1('');setfrsSearchQuery('')
                                            }}>{data?.leave_status}</b>
                                        </div>
                                        <div className='div-for-typeOfFRS'>
                                            <b>Didn't Marked:</b> <b className='others-Frs' onClick={()=>{GetTypeOfCasesDetails("others");
                                                setSearchQuery1('');setfrsSearchQuery('')}}>{data?.others}</b>
                                        </div>
                                        <div className='div-for-TotalsFRS'>
                                            <b>Total:</b> <b className='totals-Frs'>{data?.count}</b>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            </>)}
                            {showSameDayDataTable === true && showFrsemployeeSameDaydetails?.length > 0 && showFrsemployeeSameDaydetails !== "No data Found" ? (<>
                         
                              <SearchAndExportSection
                            searchValue={searchQuery1}
                            onSearchChange={(e) => setSearchQuery1(e.target.value)}
                            placeholder="Search by ID or Name or Unit"
                            handlePrint={frshandleprint}
                          />
                        </>) : ""}
                        <div className="table-container">
                            {showFrsTable === true ? (<>
                                {showFrsemployeedetails?.length > 0 && showFrsemployeedetails !== "No data Found" ? (
                                    <div className="table-wrapper">
                                        <table className="sticky-table" ref={frscomponentRef} id="table-to-xls">
                                            <thead className="sticky-header">
                                                <tr>
                                                    <th rowSpan={2} className="sticky-column sticky-header">S.No</th>
                                                    <th rowSpan={2} className="sticky-column sticky-header">Name</th>
                                                    <th rowSpan={2} className="sticky-column sticky-header">ID</th>
                                                    <th rowSpan={3} className="sticky-header">Total Days </th>
                                                    <th rowSpan={3} className="sticky-header">H Days </th>
                                                    <th rowSpan={3} className="sticky-header">W Days</th>
                                                    <th colSpan={4} className="sticky-header"style={{textAlign:"center"}}>Presents</th>
                                                    <th colSpan={3} className="sticky-header" style={{textAlign:"center"}}>Availed Leaves</th>
                                                    <th rowSpan={2} className="sticky-header">WFH</th>
                                                    <th rowSpan={2} className="sticky-header">Reg</th>
                                                    <th rowSpan={2} className="sticky-header">R Days</th>
                                                    <th rowSpan={2} className="sticky-header">LOP</th>
                                                    <th colSpan={3} className="sticky-header">Available Leaves</th>
                                                    <th rowSpan={2} className="sticky-header">Unit Name</th>
                                                    {dateRange?.map((date, index) => (
                                                        <th key={index} rowSpan={2} className="sticky-header">{formatDateToCustomHeader(date)}</th>
                                                    ))}
                                                </tr>
                                                <tr>
                                                     <th className="sticky-header">{">="}8</th>
                                                     <th  className="sticky-header">8{"<"}</th>
                                                     <th className="sticky-header">{">="}8 OD</th>
                                                     <th  className="sticky-header">8 OD{"<"}</th>
                                                     <th className="sticky-header">CL</th>
                                                     <th  className="sticky-header">SL</th>
                                                     <th className="sticky-header">EL</th>
                                                     <th className="sticky-header">CL</th>
                                                     <th  className="sticky-header">SL</th>
                                                     <th className="sticky-header">EL</th>
                                            
                                                </tr>
                                            </thead>
                                            <tbody style={{ textAlign: "left" }}>
                                                {showFrsemployeedetails && showFrsemployeedetails?.filter((element) =>
                                                    element?.emp_name?.toLowerCase().includes(frssearchQuery?.toLowerCase()) ||
                                                    element?.unit?.toLowerCase().includes(frssearchQuery?.toLowerCase()) ||
                                                    element?.cfms_id?.toString().toLowerCase().includes(frssearchQuery?.toLowerCase())
                                                )?.map((data, i) => {
                                                    const formattedData = formatEmployeeData(data, dateRange); 
                                                    return (
                                                        <tr key={i}>
                                                            <td className="sticky-column">{i + 1}</td>
                                                            <td className="sticky-column">{data?.emp_name}</td>
                                                            <td className="sticky-column">{data?.cfms_id}</td>
                                                            <td>{data?.tot_days}</td>
                                                            <td>{data?.no_holidays}</td>
                                                            <td>{data?.tot_work_days}</td>
                                                            <td>{data?.greater_8}</td>
                                                            <td>{data?.lessthan_8}</td>
                                                            <td>{data?.greater_8_od}</td>
                                                            <td>{data?.lessthan_8_od}</td>
                                                            <td>{data?.tot_leaves_appy_cl}</td>
                                                            <td>{data?.tot_leaves_appy_sl}</td>
                                                            <td>{data?.tot_leaves_appy_el}</td>
                                                            <td>{data?.tot_wfh_appy}</td>
                                                            <td>{data?.tot_regularization_appy}</td>
                                                            <td style={{backgroundColor:"seashell"}}>
                                                                <b>
                                                                {(Number(data?.greater_8) || 0) +
                                                                (Number(data?.lessthan_8) || 0) +
                                                                (Number(data?.greater_8_od) || 0) +
                                                                (Number(data?.lessthan_8_od) || 0) +
                                                                (Number(data?.tot_leaves_appy) || 0) +
                                                                (Number(data?.tot_wfh_appy) || 0) +
                                                                (Number(data?.tot_regularization_appy) || 0)}</b>
                                                            </td>
                                                            <td>{data?.irregular_clocking_with_out_leave_req}</td>
                                                            <td>{data?.cl_avaliable}</td>
                                                            <td>{data?.sl_avaliable}</td>
                                                            <td>{data?.el_avaliable}</td>
                                                            <td>{data?.unit_short_name}</td>
                                                            {formattedData?.map((entry, index) => (
                                                                <td key={index}>
                                                                    {entry?.in} <br />
                                                                    {entry?.out} <br />
                                                                    <span style={getBackgroundColor(entry?.hours)}>{entry?.hours}</span><br />
                                                                    {entry?.holiday ? (<div style={{ position: 'relative', display: 'inline-block' }}>
                                                                        <b className='cursordiv' onMouseEnter={(e) => {
                                                                            e.target.style.color = 'orange';
                                                                            const tooltip = e.target.nextSibling; tooltip.style.visibility = 'visible';
                                                                            tooltip.style.opacity = 1;}} 
                                                                        onMouseLeave={(e) => {e.target.style.color = '#000';
                                                                            const tooltip = e.target.nextSibling;
                                                                            tooltip.style.visibility = 'hidden';tooltip.style.opacity = 0;}}
                                                                        >H</b><div className='holidaydivstyle' >{entry?.holiday}</div></div>) : null}
                                                                    {entry?.frsleaves} &nbsp;{entry?.lop}
                                                                </td>
                                                            ))}
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        
                                        </table>
                                    </div>
                                ) : (showfrsErrMsg === true ? (<center><b style={{ color: "red" }}>*******No Data Found******* </b></center>) : (""))}
                            </>) : ("")}
                            {showSameDayDataTable === true ? (<>
                                    {showFilterFrsEmployeeSameDayDetails?.length > 0 && showFilterFrsEmployeeSameDayDetails !== "No data Found" ? (
                                        <div style={{ maxHeight: '600px', overflowY: 'auto' }}>
                                            <table className="sticky-table" ref={frscomponentRef} id="table-to-xls">
                                                <thead className="sticky-header">
                                                    <th className="sticky-header">S.No</th>
                                                    <th className="sticky-header">Name</th>
                                                    <th className="sticky-header">Id</th>
                                                    <th className="sticky-header">In</th>
                                                    <th className="sticky-header">Out</th>
                                                    <th className="sticky-header">Present</th>
                                                    <th className="sticky-header">On Duty</th>
                                                    <th className="sticky-header">Leave</th>
                                                    <th className="sticky-header">Unit</th>
                                                </thead>
                                                <tbody style={{ textAlign: "left" }}>
                                                    {showFilterFrsEmployeeSameDayDetails && showFilterFrsEmployeeSameDayDetails?.filter((element) =>
                                                        element?.emp_name?.toLowerCase().includes(searchQuery1?.toLowerCase()) ||
                                                        element?.unit?.toLowerCase().includes(searchQuery1?.toLowerCase()) ||
                                                        element?.cfms_id?.toString().toLowerCase().includes(searchQuery1?.toLowerCase())
                                                    )?.map((data, i) => {
                                                        return (
                                                            <tr key={i}>
                                                                <td>{i + 1}</td>
                                                                <td>{data?.emp_name}</td>
                                                                <td>{data?.cfms_id}</td>
                                                                <td>{data?.attendance_in}</td>
                                                                <td>{data?.attendance_out}</td>
                                                                <td>{data?.in_office === "N" ? "0" : "1"}</td>
                                                                <td>{data?.on_duty === "N" ? "0" : "1"}</td>
                                                                <td>{data?.leave_status}</td>
                                                                <td>{data?.unit}</td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                                <tfoot style={{ position: 'sticky', bottom: 0, backgroundColor: '#f4f4f4' }}>
                                                <td style={{ textAlign: "left" }} colSpan={5}>Total</td>
                                                <td>
                                                    {showFilterFrsEmployeeSameDayDetails?.reduce((inofficetotal, officeelement) => {
                                                    return inofficetotal + (officeelement?.in_office === "Y" ? 1 : 0);
                                                    }, 0)}
                                                </td>
                                                <td>
                                                    {showFilterFrsEmployeeSameDayDetails?.reduce((ondutytotal, ondutyitem) => {
                                                    return ondutytotal + (ondutyitem?.on_duty === "Y" ? 1 : 0);
                                                    }, 0)}
                                                </td>
                                                <td>
                                                    {showFilterFrsEmployeeSameDayDetails?.reduce((leavestatustotal, leave_statusitem) => {
                                                    return leavestatustotal + (leave_statusitem?.leave_status === "1" ? 1 : 0);
                                                    }, 0)}
                                                </td>
                                                <td ></td>
                                                </tfoot>
                                            </table>
                                        </div>
                                    ) : showErr1Msg === true ? (<center><b style={{ color: "red" }}>*********No Data Found*********</b></center>) : ("")}
                                </>) : ("")}
                        </div>
                    </Form>
                </FormikProvider>
            </jnb.Row>
        </div>
    )
}