import { ErrorMessage, Field, Form, FormikProvider, useFormik } from "formik";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import * as jnb from "react-bootstrap";
import allowNumbersOnly from "../../../CommonUtils/CommonValidations";
import { CommonAxiosGet } from "../../../CommonUtils/CommonAxios";
import { config } from "../../../CommonUtils/CommonApis";
import Sweetalert, { SweetalertOKFunction } from "../../../CommonUtils/SweetAlerts";
import React, { useState } from "react";
import Select from 'react-select';
import Swal from "sweetalert2";
import CommonPost from "../../../CommonUtils/CommonPost";
import { useSelector } from "react-redux";
const mobileValidation = /^[6-9]{1}[0-9]{9}$/;
export default function AppraisalChangeRequest() {
    const [selectedOptionsAtreq, setSelectedOptionsAtreq] = useState([]);
    const logindetailsAtApprreq = useSelector((state) => state.reducers.loginreducer.userLoginDetials);
    const empdetailsvalidation = Yup.object().shape({
        changerequesttype: Yup.string().required("Please select Change Request Type"),
        newmobile: Yup.string().when("changerequesttype", {
            is: (changerequesttype) => (changerequesttype === "mobile") ? true : false,
            then: Yup.string().required("Please Enter Mobile No").matches(mobileValidation, "Mobile number must be digits only").min(10).max(10),
        }),
        newemail: Yup.string().when("changerequesttype", {
            is: (changerequesttype) => (changerequesttype === "email") ? true : false,
            then: Yup.string().required("Please Enter Email"),
        }),
        newdob: Yup.string().when("changerequesttype", {
            is: (changerequesttype) => (changerequesttype === "dob") ? true : false,
            then: Yup.string().required("Please Select Date"),
        }),
        newdoj: Yup.string().when("changerequesttype", {
            is: (changerequesttype) => (changerequesttype === "doj") ? true : false,
            then: Yup.string().required("Please Select Date"),
        }),
        ioEmpId: Yup.string().when("changerequesttype", {
            is: (changerequesttype) => (changerequesttype === "io" || changerequesttype === "bothioro") ? true : false,
            then: Yup.string().required("Please Select IO "),
        }),
        roEmpId: Yup.string().when("changerequesttype", {
            is: (changerequesttype) => (changerequesttype === "ro" || changerequesttype === "bothioro") ? true : false,
            then: Yup.string().required("Please Select RO "),
        }),
        zone_id: Yup.string().when("changerequesttype", {
            is: (changerequesttype) => (changerequesttype === "designation") ? true : false,
            then: Yup.string().required("Please Select Zone"),
        }),
        designation_id: Yup.string().when("changerequesttype", {
            is: (changerequesttype) => (changerequesttype === "designation") ? true : false,
            then: Yup.string().required("Please Select Designation"),
        }),


    });
    const appraisalChangeformIk = useFormik({
        enableReinitialize: true,
        initialValues: {
            empid: "",
            newmobile: "",
            changerequesttype: "",
            newemail: "",
            ioEmpId: "",
            roEmpId: "",
            newdoj: "",
            newdob: "",
            zone_id: "",
            designation_id: "",
        },
        onSubmit: (values) => {
            if (appraisalChangeformIk.values.changerequesttype === "io" || appraisalChangeformIk.values.changerequesttype === "ro" || appraisalChangeformIk.values.changerequesttype === "bothioro") {
                EmpupdatepostAlert(values)
            }
            else {
                EmpupdateSaveAlert(values)
            }
        },
        validationSchema: empdetailsvalidation,
    });

    const handleEmpUpdate = (valuesEmpupdate, typeEmpupdate) => {
        const empidforEmpupdate = showempdetailsAtapprreq[0].cfms_id;
        valuesEmpupdate.designation_id = parseInt(valuesEmpupdate.designation_id);
        CommonPost.empupdatePost(typeEmpupdate, empidforEmpupdate, valuesEmpupdate).then((resforempupdate) => {
                if (resforempupdate.data.scode === "01") {
                    SweetalertOKFunction('Successfully Updated ', 'success').then(function (isConfirm) {
                        if (isConfirm.value) {
                            GetEmpDatabyempid();
                            appraisalChangeformIk.setFieldValue("newmobile", "");
                            appraisalChangeformIk.setFieldValue("newemail", "");
                            appraisalChangeformIk.setFieldValue("ioEmpId", "");
                            appraisalChangeformIk.setFieldValue("roEmpId", "");
                            appraisalChangeformIk.setFieldValue("newrole", []);
                            setSelectedOptionsAtreq([]);
                            appraisalChangeformIk.setFieldValue("zone_id", "");
                            appraisalChangeformIk.setFieldValue("designation_id", "");
                        }
                    });
                } else if (resforempupdate.data.scode === "02") {
                    Sweetalert(resforempupdate.data.sdesc, 'warning');
                } else {
                    Swal.fire('Canceled', 'Something went wrong,Please check', 'error');
                }
            })
            .catch(() => {console.log(null);});
    };
    
    const showConfirmationAlert = (valuesforconfirmalert, typeforconfirmalert) => {
        Swal.fire({
            title: 'Are you sure you want to submit?',
            text: 'Please check it once before submitting',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Submit',
            cancelButtonText: 'Cancel',
            reverseButtons: true,
        }).then((result) => {
            if (result.value) {
                handleEmpUpdate(valuesforconfirmalert, typeforconfirmalert);
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire('Canceled', 'Do corrections and then retry :)', 'error');
            }
        });
    };
    
    const EmpupdatepostAlert = (empupdatepostvalues) => {
        const typepost = appraisalChangeformIk.values.changerequesttype;
        showConfirmationAlert(empupdatepostvalues, typepost);
    };
    
    const EmpupdateSaveAlert = (empupdateSavevalues) => {
        const typeempsave = appraisalChangeformIk.values.changerequesttype;
        showConfirmationAlert(empupdateSavevalues, typeempsave);
    };
    const alloptions = [
        { label: "Employee", value: 1 },
        { label: "IO", value: 2 },
        { label: "RO", value: 3 },
    ];
    function ClearAllValues() {
        appraisalChangeformIk.setFieldValue("newmobile", "");
        appraisalChangeformIk.setFieldValue("newemail", "");
        appraisalChangeformIk.setFieldValue("ioEmpId", "");
        appraisalChangeformIk.setFieldValue("roEmpId", "");
        appraisalChangeformIk.setFieldValue("newrole", []);
        setSelectedOptionsAtreq([]);
        appraisalChangeformIk.setFieldValue("zone_id", "");
        appraisalChangeformIk.setFieldValue("designation_id", "");
    }
    const [optionsAtapprreq, setoptionsAtapprreq] = useState([]);
    const [showempRoleAtapprreq, setempRoleAtapprreq] = useState([]);
    const [showempdetailsAtapprreq, setempdetailsAtapprreq] = useState();
    const [showempstatusflag, setempstatusflag] = useState(false);
    function GetEmpDatabyempid() {
        CommonAxiosGet(config.url.COMMON_URL + "employeedetails/" + appraisalChangeformIk.values.empid).then((response) => {
            if (response.data.scode === "01") {
                setempdetailsAtapprreq(response.data.emp_details);

                const roles = response.data.emp_details[1].roles;
                const mappedRoles = roles.map((role) => {
                    if (role === 1) return "EMP";
                    if (role === 2) return "IO";
                    if (role === 3) return "RO";
                    return "";
                });
                setempRoleAtapprreq(mappedRoles);
                setoptionsAtapprreq(alloptions.filter((data) => !roles.includes(data.value)));
                setempstatusflag(true);
                Getemployeesdata();
                if (appraisalChangeformIk.values.changerequesttype === "designation") {
                    ZoneListAtApprChangeReq();
                    appraisalChangeformIk.setFieldValue("zone_id", response?.data?.emp_details[0]?.zone_id)
                    GetdesignationsdataAtApprChangeReq(response?.data?.emp_details[0]?.zone_id)
                }
            } else if (response.data.scode === "02") {
                Sweetalert(response.data.sdesc, "warning");
            }
        });
    }

    const handleMultiSelectChange = (selectedOptionsReq) => {
        if (selectedOptionsReq !== null) {
            appraisalChangeformIk.setFieldValue("newrole", selectedOptionsReq.map((rowdata) => rowdata.value))}
        else {console.log(null)}
        setSelectedOptionsAtreq(selectedOptionsReq.value);
    };
    const [showemployeelist, setemployeeList] = useState([]);
    function Getemployeesdata() {
        CommonAxiosGet(config.url.COMMON_URL + "employee/" + logindetailsAtApprreq.roles[0] + "/0").then((response) => {
            setemployeeList(response.data.employee_data_list);
        });
    }
    const [showZoneMaster, setZoneMaster] = useState([]);
    const [showDesignationsData, setDesignationsData] = useState([]);
    function ZoneListAtApprChangeReq() {
        CommonAxiosGet(config.url.COMMON_URL + "zones").then((responsezone) => {
            if (responsezone.data.scode === "01") {
                setZoneMaster(responsezone.data.data);
            } else if (responsezone.data.scode === "02") {
                Sweetalert(responsezone.data.sdesc, "warning");
            }
        });
    }
    function GetdesignationsdataAtApprChangeReq(zoneidApprChangeReq) {
        if (zoneidApprChangeReq != null && zoneidApprChangeReq != "" && zoneidApprChangeReq != undefined) {
            CommonAxiosGet(config.url.COMMON_URL + "designationkps/" + zoneidApprChangeReq).then((resatZoneId) => {
                if (resatZoneId.data.scode === "01") {setDesignationsData(resatZoneId.data.data)}
                else if (resatZoneId.data.scode === "02") {
                    Sweetalert(resatZoneId.data.sdesc, 'warning')
                    setDesignationsData([])}})}}
    return (<>
        <div className="card">
            <div className="card-body">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to="/homepage">Home</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">Employee List</li>
                </ol>
            </div>
        </div>
        <FormikProvider value={appraisalChangeformIk}>
            <Form onSubmit={appraisalChangeformIk.handleSubmit} onChange={appraisalChangeformIk.handleChange}>
                <jnb.Row className="px-3 pt-4">
                    <jnb.Col xs={12} sm={12} md={12} lg={2} xl={2} xxl={2} style={{ fontSize: "16px" }}><b>Change Request Type<font style={{ color: "red" }}>*</font>:</b></jnb.Col>
                    <jnb.Col xs={12} sm={12} md={12} lg={12} xl={2} xxl={2}>
                        <jnb.InputGroup>
                            <Field as="select" className='form-control' name="changerequesttype">
                                <option value="">--Select--</option>
                                <option value="mobile">Mobile Number Change</option>
                                <option value="email">Email Change</option>
                                <option value="doj">Date of Joining Change</option>
                                <option value="dob">Date of Birth Change</option>
                                <option value="io">IO Change</option>
                                <option value="ro">RO Change</option>
                                <option value="bothioro">IO & RO Change</option>
                                <option value="role">Role Mapping</option>
                                <option value="designation">Designation Change</option>
                            </Field>
                            <ErrorMessage name="changerequesttype" component="div" className="text-danger" ></ErrorMessage>
                        </jnb.InputGroup>
                    </jnb.Col>
                    <jnb.Col xs={12} sm={12} md={12} lg={2} xl={2} xxl={2} style={{ fontSize: "16px" }}>
                        <b>Employee ID<font style={{ color: "red" }}>*</font>:</b></jnb.Col>
                    <jnb.Col xs={12} sm={12} md={12} lg={12} xl={2} xxl={2}>
                        <jnb.InputGroup>
                            <Field type="text" className='form-control' name="empid" onKeyPress={allowNumbersOnly} maxLength="9"
                                onChange={(e) => { setempstatusflag(false); ClearAllValues(); }}
                            ></Field>
                            <ErrorMessage name="empid" component="div" className="text-danger" ></ErrorMessage>
                        </jnb.InputGroup>
                    </jnb.Col>
                    <jnb.Col xs={12} sm={12} md={12} lg={12} xl={1} xxl={1}>
                        <button type="button" className="btn rounded btn-success " style={{ float: "right" }}
                            onClick={(e) => { GetEmpDatabyempid(); }}>Get</button>
                    </jnb.Col>
                </jnb.Row>
                {showempstatusflag && (<>
                    <legend >Employee Data:</legend>
                    <table className="table table-condensed table-bordered table-striped table-responsive">
                        <thead>
                            <tr>
                                <th align="center">S.No</th>
                                <th>ID</th>
                                <th>Name</th>
                                <th>Mobile No</th>
                                <th>Designation</th>
                                <th>Email</th>
                                <th>IO</th>
                                <th>RO</th>
                            </tr>
                        </thead>
                        <tbody style={{ textAlign: "left" }}>
                            {showempdetailsAtapprreq.length > 0 ? (<>
                                <tr>
                                    <td width="100px" align="center">{ 1}</td>
                                    <td>{showempdetailsAtapprreq[0].cfms_id}</td>
                                    <td>{showempdetailsAtapprreq[0].emp_name}</td>
                                    <td>{showempdetailsAtapprreq[0].mobileno}</td>                    <td>{showempdetailsAtapprreq[0].new_designation}</td>
                                    <td>{showempdetailsAtapprreq[0].email}</td>
                                    <td>{showempdetailsAtapprreq[0].io_id}-{showempdetailsAtapprreq[0].immidiate_manager_name}</td>
                                    <td>{showempdetailsAtapprreq[0].ro_id}-{showempdetailsAtapprreq[0].reporting_manager_name}</td>
                                </tr>
                            </>) : null}
                        </tbody>
                    </table>

                    <jnb.Row className="px-3 pt-4">
                        {appraisalChangeformIk.values.changerequesttype === "mobile" && (<>
                            <jnb.Col xs={12} sm={12} md={12} lg={2} xl={2} xxl={2} style={{ fontSize: "16px" }}><b>Existing Mobile Number:</b></jnb.Col>
                            <jnb.Col xs={12} sm={12} md={12} lg={12} xl={2} xxl={2}>
                                {showempdetailsAtapprreq[0].mobileno}
                            </jnb.Col>
                            <jnb.Col xs={12} sm={12} md={12} lg={2} xl={2} xxl={2} style={{ fontSize: "16px" }}><b>New Mobile Number
                                <font style={{ color: "red" }}>*</font>:</b></jnb.Col>
                            <jnb.Col xs={12} sm={12} md={12} lg={12} xl={2} xxl={2}>
                                <jnb.InputGroup>
                                    <Field type="text" className='form-control' name="newmobile" onKeyPress={allowNumbersOnly} maxLength="10" ></Field>
                                </jnb.InputGroup>
                                <ErrorMessage name="newmobile" component="div" className="text-danger" ></ErrorMessage>
                            </jnb.Col>
                        </>)}

                        {appraisalChangeformIk.values.changerequesttype === "email" && (<>
                            <jnb.Col xs={12} sm={12} md={12} lg={2} xl={2} xxl={2} style={{ fontSize: "16px" }}><b>Existing Email:</b></jnb.Col>
                            <jnb.Col xs={12} sm={12} md={12} lg={12} xl={2} xxl={2}>
                                {showempdetailsAtapprreq[0].email}
                            </jnb.Col>
                            <jnb.Col xs={12} sm={12} md={12} lg={2} xl={2} xxl={2} style={{ fontSize: "16px" }}><b>New Email<font style={{ color: "red" }}>*</font>:</b></jnb.Col>
                            <jnb.Col xs={12} sm={12} md={12} lg={12} xl={2} xxl={2}>
                                <jnb.InputGroup>
                                    <Field type="email" className='form-control' name="newemail"></Field>
                                </jnb.InputGroup>
                                <ErrorMessage name="newemail" component="div" className="text-danger" ></ErrorMessage>
                            </jnb.Col>
                        </>)}
                        {appraisalChangeformIk.values.changerequesttype === "io" && (<>
                            <jnb.Col xs={12} sm={12} md={12} lg={2} xl={2} xxl={2} style={{ fontSize: "16px" }}><b>Existing IO :</b></jnb.Col>
                            <jnb.Col xs={12} sm={12} md={12} lg={12} xl={2} xxl={2}>
                                {showempdetailsAtapprreq[0].io_id}-{showempdetailsAtapprreq[0].immidiate_manager_name}
                            </jnb.Col>
                            <jnb.Col xs={12} sm={12} md={12} lg={2} xl={2} xxl={2} style={{ fontSize: "16px" }}>
                                <b>New IO <font style={{ color: "red" }}>*</font>:</b></jnb.Col>
                            <jnb.Col xs={12} sm={12} md={12} lg={12} xl={3} xxl={3}>
                                <jnb.InputGroup>
                                    <Select className="form-control" name="ioEmpId" isSearchable={true} isClearable={true}
                                        options={showemployeelist}
                                        getOptionLabel={optionapprIO => optionapprIO.emp_id + "-" + optionapprIO.emp_name} getOptionValue={optionapprValue => optionapprValue.emp_id}
                                        value={showemployeelist?.find(itapprIO => itapprIO?.emp_id === appraisalChangeformIk?.values?.ioEmpId) || null}
                                        onChange={(selectedOptionAtapprIO) => {
                                            if (selectedOptionAtapprIO !== null) {appraisalChangeformIk.setFieldValue("ioEmpId", selectedOptionAtapprIO.emp_id)}
                                            else {appraisalChangeformIk.setFieldValue("ioEmpId", "")}}}/>
                                </jnb.InputGroup>
                                <ErrorMessage name="ioEmpId" component="div" className="text-danger" ></ErrorMessage>
                            </jnb.Col>
                        </>)}
                        {appraisalChangeformIk.values.changerequesttype === "ro" && (<>
                            <jnb.Col xs={12} sm={12} md={12} lg={2} xl={2} xxl={2} style={{ fontSize: "16px" }}><b>Existing Ro :</b></jnb.Col>
                            <jnb.Col xs={12} sm={12} md={12} lg={12} xl={2} xxl={2}>
                                {showempdetailsAtapprreq[0].ro_id}-{showempdetailsAtapprreq[0].reporting_manager_name}
                            </jnb.Col>
                            <jnb.Col xs={12} sm={12} md={12} lg={2} xl={2} xxl={2} style={{ fontSize: "16px" }}>
                                <b>New RO <font style={{ color: "red" }}>*</font>:</b></jnb.Col>
                            <jnb.Col xs={12} sm={12} md={12} lg={12} xl={3} xxl={3}>
                                <jnb.InputGroup>
                                    <Select className="form-control" name="roEmpId" isSearchable={true} isClearable={true}
                                        options={showemployeelist}
                                        getOptionLabel={optionRolabel => optionRolabel.emp_id + "-" + optionRolabel.emp_name} getOptionValue={optionRoValue => optionRoValue.emp_id}
                                        value={showemployeelist?.find(itRoValue => itRoValue?.emp_id === appraisalChangeformIk?.values?.roEmpId) || null}
                                        onChange={(selectedOptionAtapprRo) => {
                                            if (selectedOptionAtapprRo !== null) {appraisalChangeformIk.setFieldValue("roEmpId", selectedOptionAtapprRo?.emp_id)}
                                            else {appraisalChangeformIk.setFieldValue("roEmpId", "")}}}/>
                                </jnb.InputGroup>
                                <ErrorMessage name="roEmpId" component="div" className="text-danger" ></ErrorMessage>
                            </jnb.Col>
                        </>)}

                        {appraisalChangeformIk.values.changerequesttype === "bothioro" && (<>
                            <jnb.Row className="px-3 pt-4">
                                <jnb.Col xs={12} sm={12} md={12} lg={2} xl={2} xxl={2} style={{ fontSize: "16px" }}><b>Existing IO :</b></jnb.Col>
                                <jnb.Col xs={12} sm={12} md={12} lg={12} xl={2} xxl={2}>
                                {showempdetailsAtapprreq[0].io_id}-{showempdetailsAtapprreq[0].immidiate_manager_name}
                                </jnb.Col>
                                <jnb.Col xs={12} sm={12} md={12} lg={2} xl={2} xxl={2} style={{ fontSize: "16px" }}>
                                    <b>New IO <font style={{ color: "red" }}>*</font>:</b></jnb.Col>
                                <jnb.Col xs={12} sm={12} md={12} lg={12} xl={3} xxl={3}>
                                    <jnb.InputGroup>
                                        <Select className="form-control" name="roEmpId" isSearchable={true} isClearable={true}
                                            options={showemployeelist}
                                            getOptionLabel={optionbothlabel => optionbothlabel.emp_id + "-" + optionbothlabel.emp_name} getOptionValue={optionbothvalue => optionbothvalue.emp_id}
                                            value={showemployeelist?.find(itboth => itboth?.emp_id === appraisalChangeformIk?.values?.ioEmpId) || null}
                                            onChange={(selectedOptionboth) => {
                                                if (selectedOptionboth !== null) {appraisalChangeformIk.setFieldValue("ioEmpId", selectedOptionboth?.emp_id)}
                                                else {appraisalChangeformIk.setFieldValue("ioEmpId", "")}}}/>

                                    </jnb.InputGroup>
                                    <ErrorMessage name="ioEmpId" component="div" className="text-danger" ></ErrorMessage>
                                </jnb.Col>
                            </jnb.Row>
                            <jnb.Row className="px-3 pt-4">
                                <jnb.Col xs={12} sm={12} md={12} lg={2} xl={2} xxl={2} style={{ fontSize: "16px" }}><b>Existing RO :</b></jnb.Col>
                                <jnb.Col xs={12} sm={12} md={12} lg={12} xl={2} xxl={2}>
                                    {showempdetailsAtapprreq[0].ro_id}-{showempdetailsAtapprreq[0].reporting_manager_name}
                                </jnb.Col>
                                <jnb.Col xs={12} sm={12} md={12} lg={2} xl={2} xxl={2} style={{ fontSize: "16px" }}>
                                    <b>New RO <font style={{ color: "red" }}>*</font>:</b></jnb.Col>
                                <jnb.Col xs={12} sm={12} md={12} lg={12} xl={3} xxl={3}>
                                    <jnb.InputGroup>
                                        <Select className="form-control" name="roEmpId" isSearchable={true} isClearable={true}
                                            options={showemployeelist}
                                            getOptionLabel={optionroEmpId => optionroEmpId.emp_id + "-" + optionroEmpId.emp_name} getOptionValue={optionroEmp => optionroEmp.emp_id}
                                            value={showemployeelist?.find(itoptionroEmp => itoptionroEmp?.emp_id === appraisalChangeformIk?.values?.roEmpId) || null}
                                            onChange={(selectedOptionroEmp) => {
                                                if (selectedOptionroEmp !== null) {appraisalChangeformIk.setFieldValue("roEmpId", selectedOptionroEmp.emp_id);}
                                                else {appraisalChangeformIk.setFieldValue("roEmpId", "");}}}/>
                                    </jnb.InputGroup>
                                    <ErrorMessage name="roEmpId" component="div" className="text-danger" ></ErrorMessage>
                                </jnb.Col>
                            </jnb.Row>
                        </>)}
                        {appraisalChangeformIk.values.changerequesttype === "doj" && (<>
                            <jnb.Row className="px-3 pt-4">
                                <jnb.Col xs={12} sm={12} md={12} lg={2} xl={2} xxl={2} style={{ fontSize: "16px" }}><b>Existing DOJ :</b></jnb.Col>
                                <jnb.Col xs={12} sm={12} md={12} lg={12} xl={2} xxl={2}>
                                    {showempdetailsAtapprreq[0].doj}
                                </jnb.Col>
                                <jnb.Col xs={12} sm={12} md={12} lg={2} xl={2} xxl={2} style={{ fontSize: "16px" }}>
                                    <b>New DOJ<font style={{ color: "red" }}>*</font>:</b></jnb.Col>
                                <jnb.Col xs={12} sm={12} md={12} lg={12} xl={3} xxl={3}>
                                    <jnb.InputGroup>
                                        <Field type="date" className='form-control' name="newdoj"></Field>
                                    </jnb.InputGroup>
                                    <ErrorMessage name="newdoj" component="div" className="text-danger" ></ErrorMessage>
                                </jnb.Col>
                            </jnb.Row>
                        </>)}

                        {appraisalChangeformIk.values.changerequesttype === "dob" && (<>
                            <jnb.Row className="px-3 pt-4">
                                <jnb.Col xs={12} sm={12} md={12} lg={2} xl={2} xxl={2} style={{ fontSize: "16px" }}><b>Existing DOB :</b></jnb.Col>
                                <jnb.Col xs={12} sm={12} md={12} lg={12} xl={2} xxl={2}>
                                    {showempdetailsAtapprreq[0].dob}
                                </jnb.Col>
                                <jnb.Col xs={12} sm={12} md={12} lg={2} xl={2} xxl={2} style={{ fontSize: "16px" }}>
                                    <b>New DOB<font style={{ color: "red" }}>*</font>:</b></jnb.Col>
                                <jnb.Col xs={12} sm={12} md={12} lg={12} xl={3} xxl={3}>
                                    <jnb.InputGroup>
                                        <Field type="date" className='form-control' name="newdob"></Field>
                                    </jnb.InputGroup>
                                    <ErrorMessage name="newdob" component="div" className="text-danger" ></ErrorMessage>
                                </jnb.Col>
                            </jnb.Row>
                        </>)}


                        {appraisalChangeformIk.values.changerequesttype === "role" && (<>
                            <jnb.Row className="px-3 pt-4">
                                <jnb.Col xs={12} sm={12} md={12} lg={2} xl={1} xxl={1} style={{ fontSize: "16px" }}><b>Roles :</b></jnb.Col>
                                <jnb.Col xs={12} sm={12} md={12} lg={12} xl={1} xxl={1}>
                                    <ul>
                                        {showempRoleAtapprreq.map((role, index) => (
                                            <li key={index}>{role}&nbsp;</li>
                                        ))}
                                    </ul>
                                </jnb.Col>
                                <jnb.Col xs={12} sm={12} md={12} lg={12} xl={2} xxl={2}></jnb.Col>
                                <jnb.Col xs={12} sm={12} md={12} lg={2} xl={2} xxl={2} style={{ fontSize: "16px" }}>
                                    <b>New Role <font style={{ color: "red" }}>*</font>:</b></jnb.Col>
                                <jnb.Col xs={12} sm={12} md={12} lg={12} xl={3} xxl={3}>
                                    <jnb.InputGroup>
                                        <Select className="form-control" name="newrole" isSearchable={true} isClearable={true}
                                            options={optionsAtapprreq}
                                            isMulti
                                            onChange={(selectedOptionsAtreq) => handleMultiSelectChange(selectedOptionsAtreq)}
                                            value={selectedOptionsAtreq}/>
                                    </jnb.InputGroup>
                                    <ErrorMessage name="newrole" component="div" className="text-danger" ></ErrorMessage>
                                </jnb.Col>

                            </jnb.Row>
                        </>)}

                        {appraisalChangeformIk.values.changerequesttype === "designation" && (<>
                            <jnb.Row className="px-3 pt-4">
                                <jnb.Col xs={12} sm={12} md={12} lg={2} xl={2} xxl={2} style={{ fontSize: "16px" }}><b>Existing Zone :</b></jnb.Col>
                                <jnb.Col xs={12} sm={12} md={12} lg={12} xl={2} xxl={2}>
                                    {showempdetailsAtapprreq[0].zone_name}
                                </jnb.Col>
                                <jnb.Col xs={12} sm={12} md={12} lg={2} xl={2} xxl={2} style={{ fontSize: "16px" }}>
                                    <b>New Zone <font style={{ color: "red" }}>*</font>:</b></jnb.Col>
                                <jnb.Col xs={12} sm={12} md={12} lg={12} xl={3} xxl={3}>
                                    <Field component="select" name="zone_id" className="form-select mb-3"
                                        onChange={(e) => { GetdesignationsdataAtApprChangeReq(e.target.value); }} disabled={true}>
                                        <option value="">---Select--</option>
                                        {showZoneMaster != undefined && showZoneMaster?.map((datasreq, reqIndex) => {
                                            return (<React.Fragment key={reqIndex}>
                                                <option key={reqIndex} value={datasreq.zone_id}>
                                                    {datasreq.zone_name}
                                                </option>
                                            </React.Fragment>);
                                        })}
                                    </Field>
                                    <ErrorMessage name="zone_id" component="div" className="text-error" />
                                </jnb.Col>
                            </jnb.Row>
                            <jnb.Row className="px-3 pt-4">
                                <jnb.Col xs={12} sm={12} md={12} lg={2} xl={2} xxl={2} style={{ fontSize: "16px" }}><b>Existing Designation:</b></jnb.Col>
                                <jnb.Col xs={12} sm={12} md={12} lg={12} xl={2} xxl={2}>
                                    {showempdetailsAtapprreq[0].new_designation}
                                </jnb.Col>
                                <jnb.Col xs={12} sm={12} md={12} lg={2} xl={2} xxl={2} style={{ fontSize: "16px" }}>
                                    <b>New Designation <font style={{ color: "red" }}>*</font>:</b></jnb.Col>
                                <jnb.Col xs={12} sm={12} md={12} lg={12} xl={3} xxl={3}>
                                    <Field component="select" name="designation_id" className="form-select mb-3">
                                        <option value="">---Select--</option>
                                        {showDesignationsData != undefined && showDesignationsData?.map((depreq, depIndex) => {
                                            return (<React.Fragment key={depIndex}>
                                                <option value={depreq.designation_id}>{depreq.designation_name}</option>
                                            </React.Fragment>)
                                        })}
                                    </Field>
                                    <ErrorMessage name="designation_id" component="div" className="text-error" />
                                </jnb.Col>
                            </jnb.Row>
                        </>)}

                    </jnb.Row>
                    <jnb.Row className="px-3 pt-4">
                        <div className="text-end mb-3">
                            <button type="submit" className="btn rounded-0 btn-success btn-sm">Submit</button>
                        </div>
                    </jnb.Row>
                </>)}
            </Form>
        </FormikProvider>
    </>)
}